import React, {useEffect, useState} from 'react'
import {Container, Row, Col, Image } from 'react-bootstrap'
import Twitter from '../images/Logos/Twitter.png'
import Instagram from '../images/Logos/Instagram.png'
import './Footer.css'

function Footer() {
    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowDimension <= 640;

    return (
        <>
            {isMobile ? (<></>) : 
            (
                <Container fluid>
                    <Row className='footer' xl={3} lg={3} md={3} sm={3} xs={1} noGutters = {false}>
                        <Col xl={{ order: 1 }} lg={{ order: 1 }} md={{ order: 1 }} sm={{ order: 1 }} xs={{ order: 1 }}>
                            Contact Us
                            <br />
                            <p className='small nomargin'>thebrainpicks@gmail.com</p>
                        </Col>
                        <Col xl={{ order: 2 }} lg={{ order: 2 }} md={{ order: 2 }} sm={{ order: 2 }} xs={{ order: 3 }}>
                            Follow Us
                            <br />
                            <a href='https://twitter.com/TheBrainPicks' target='_blank'>
                                <Image src={Twitter} rounded className='socialIcons' />
                            </a>
                            <a href='https://www.instagram.com/thebrainpicks/' target='_blank'>
                                <Image src={Instagram} rounded className='socialIcons' />
                            </a>
                        </Col>
                        <Col xl={{ order: 3 }} lg={{ order: 3 }} md={{ order: 3 }} sm={{ order: 3 }} xs={{ order: 2 }}>
                            App Store
                            <p className='small nomargin'>coming soon</p>
                        </Col>
                    </Row>
                    <Row className='sixthRow' noGutters = {false}>
                        <Col>@2024 THE BRAIN FUND</Col>
                    </Row>
                </Container>
            )} 
        </>
    );
}
export default Footer