import React, { useState, useEffect } from 'react';
import './CreateBet.css';
import createBet from '../../MongoDb/CreateBet';
import getTeams from '../../MongoDb/GetTeams';
import getBetSummary from '../../MongoDb/GetBetSummary';
import InputForm from '../InputForm';
import { ToggleButton} from 'react-bootstrap';
import MyVerticallyCenteredModal from '../BetConfirmModal'
import { Redirect } from 'react-router-dom';
import LeaguePicker from "../LeaguePicker";


function CreateBetForm() {
  const now = new Date();
  const easternTimezoneOffset = -5 * 60; // US Eastern Timezone offset in minutes (Standard Time, adjust for DST if needed)
  const utcTime = now.getTime() + now.getTimezoneOffset() * 60000;
  const easternTime = new Date(utcTime + easternTimezoneOffset * 60000);
  const year = easternTime.getFullYear();
  const month = (easternTime.getMonth() + 1).toString().padStart(2, '0');
  const day = easternTime.getDate().toString().padStart(2, '0');
  const today = `${year}-${month}-${day}`;

  const [betData, setBetData] = useState({
    Bet_Date: today,
    Bet_Team: '',
    Bet_Team_Short: '',
    Wager_Type: '',
    Spread: '',
    Sport: '',
    Units: '1',
    Odds: '-110',
    Risk_Amount: '',
    To_Win_Amount: '',
  });

  const [teamsData, setTeamsData] = useState([]);
  const [filteredTeamsData, setFilteredTeamsData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [unit, setUnit] = useState(0);
  const [password, setPassword] = useState("");
  //turns true when bet is sent to server
  const [betSent, setBetSent] = React.useState(false);
  //depends on response from server
  const [betSuccess, setBetSuccess] = React.useState(false);
  //modal that confirms bet success or fail toggle
  const [modalShow, setModalShow] = React.useState(false);
  //toggle that navigates back to the bets page to confirm new bet is created
  const [shouldNavigate, setShouldNavigate] = useState(false);

  const [selectedLeague, setSelectedLeague] = useState('ALL');
  const [windowDimension, setWindowDimension] = useState(null);
  const isMultiSelect = false;

  // Function to get the "Short" name based on the "Name" field
  function getShortName(nameToFind) {
    for (const item of teamsData) {
      if (item.Name === nameToFind) {
        return item.Short;
      }
    }
    return null; // Return null if the name is not found
  }
  function getLeague(nameToFind) {
    if (selectedLeague[0] === 'NCAA' && selectedLeague[1] != null) {
      return selectedLeague[1];
    }
    for (const item of teamsData) {
      if (item.Name === nameToFind) {
        return item.League;
      }
    }
    return null; // Return null if the name is not found
  }
  // This calls the createBet API request when the form is submitted
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'Bet_Team') {
      setBetData((prevData) => ({
        ...prevData,
        Bet_Team: value,
        Bet_Team_Short: getShortName(value), // Set the ShortName value as needed
        Sport: getLeague(value)
      }));
    } else if (name === 'Password') {
      setPassword((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      console.log('Other Change: ' + value)
      setBetData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // This calls the createBet API request when the form is submitted
  const handleSubmit = (event) => {
    event.preventDefault();
    if (betSent != true) {
      setBetSent(true)
      createBet(event, betData, password)
      .then((response) => {
        console.log('Bet creation response:', response);
        setBetSent(false)
        setBetSuccess(true)
        setModalShow(true)
        // You can perform additional actions based on the response
      })
      .catch((error) => {
        // Handle any errors that occur during the promise execution
        console.error('Bet creation error:', error);
        setBetSent(false)
        setBetSuccess(false)
        setModalShow(true)
      });
      }
  };

  // This effect handles setting the Risk Amount and To Win Amounts based on the units and odds selected
  useEffect(() => {
    let toWinCalc = 0;
    let riskAmountCalc = 0;
    const profitAmount = betData.Units * unit
    if (betData.Odds < 0){
        toWinCalc = profitAmount;
        riskAmountCalc = profitAmount / (100/Math.abs(betData.Odds))
    } else {
        toWinCalc = profitAmount * (betData.Odds/100);
        riskAmountCalc = profitAmount;
    }
    setBetData((prevData) => ({
        ...prevData,
        To_Win_Amount: toWinCalc.toFixed(2),
    }));
    setBetData((prevData) => ({
        ...prevData,
        Risk_Amount: riskAmountCalc.toFixed(2),
    }));
  }, [betData.Odds, betData.Units, unit]);


  // This effect runs on first load and get the teams and unit size from the API
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const teams = await getTeams();
    
        // Sort the teams array based on the 'Name' field
        const sortedTeams = [...teams].sort((a, b) => {
          const nameA = a.Name.toLowerCase();
          const nameB = b.Name.toLowerCase();
          return nameA.localeCompare(nameB);
        });
        
        const teamsWithDummy = [{ Name: "-" }, ...sortedTeams];
        // Set the sorted teams in the state
        setTeamsData(teamsWithDummy);
        setFilteredTeamsData(teamsWithDummy);
      } catch (error) {
        console.error('Failed to get teams:', error);
      }
    };

    const fetchUnit = async () => {
        try {
          const betSummary = await getBetSummary();
          setUnit(betSummary['Unit']);
        } catch (error) {
          console.error('Failed to get bet summary:', error);
        }
    };
    fetchUnit();
    fetchTeams();
  }, []);
  
  const handleLeagueSelection = (shortName) => {
    // Update the selected league immediately
    setSelectedLeague(shortName);
  };
  
  // Use useEffect to filter teams when selectedLeague changes
  useEffect(() => {
    const filteredTeams = teamsData.filter((team) => selectedLeague.includes(team.League));
    const teamsWithDummy = [{ Name: "-" }, ...filteredTeams];
    setFilteredTeamsData(teamsWithDummy);
  }, [selectedLeague]); // Add selectedLeague as a dependency

    // This holds all the details of how to render the form, and controls the changes on custom bet selections
  const formFields = [
      {
      label: 'Bet Team',
      type: checked ? 'text' : 'select',
      name: 'Bet_Team',
      options: filteredTeamsData.map((team) => ({ label: team.Name, value: team.Name })),
      },
      {
          label: 'Bet Date',
          type: 'date',
          name: 'Bet_Date',
      },
      {
          label: 'Wager Type',
          type: 'select',
          name: 'Wager_Type',
          options: [
          { label: 'Select a type', value: '' },
          { label: 'Moneyline', value: 'Moneyline' },
          { label: 'Spread', value: 'Spread' },
          { label: '1H Spread', value: '2H Spread' },
          { label: '2H Spread', value: '2H Spread' },
          { label: 'Over', value: 'Over' },
          { label: 'Under', value: 'Under' },
          { label: 'Draw', value: 'Draw' },
          { label: 'Parlay', value: 'Parlay' },
          ],
      },
      {
          label: 'Spread',
          type: 'number',
          name: 'Spread',
      },
      {
          label: 'Units',
          type: 'number',
          name: 'Units',
      },
      {
          label: 'Odds',
          type: 'number',
          name: 'Odds',
      },
      {
          label: 'Risk Amount',
          type: 'number',
          name: 'Risk_Amount',
          disabled: true,
      },
      {
          label: 'To Win Amount',
          type: 'number',
          name: 'To_Win_Amount',
          disabled: true,
      }
  ];

  return (
    <div>
        <ToggleButton
            className="mb-2 mt-2 ml-2 btn-sm"
            id="toggle-check"
            type="checkbox"
            variant="dark"
            checked={checked}
            value={true}
            onChange={(e) => setChecked(e.currentTarget.checked)}
        >Custom Team
        </ToggleButton>
        <br/>
        <LeaguePicker onSelectLeagues={handleLeagueSelection} width={windowDimension < 640 ? 20 : 40} isMultiSelect={isMultiSelect} />
        <InputForm
            handleSubmit={handleSubmit}
            data={betData}
            handleInputChange={handleInputChange}
            formGroups={formFields}
        />
        {betSuccess
        ? 
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            setShouldNavigate(true);
          }}
          title='Bet Created'
          header='Bet has been created for your fund'
          body='If you made a mistake please contact your admin for assistance. Otherwise verify on the bets page for today
          that your bet has been correctly created'
          close='Confirm'
        />
        : 
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          title='Bet not Sent'
          header='Please try again'
          body='The password or some details of the bet were incorrectly logged please try again'
          close='Close'
        />}
        {shouldNavigate && <Redirect to="/bets_home" />}
    </div>
  );
}

export default CreateBetForm;
