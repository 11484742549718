import { url } from "./MongoConstants";

const createBet = async (event, betData) => {
    event.preventDefault();

    // Make the POST request to the server
    try {
        console.log(JSON.stringify(betData))
        const server = url;
        const response = await fetch(server + '/api/create/bet', {
            method: 'POST',
            headers: {'Content-Type': 'application/json',},
            body: JSON.stringify(betData),
        });

        if (response.ok) {
            const data = await response.json();
            console.log('Bet created successfully:', data);
            return(data)
        } else {
            console.log('Failed to create bet');
            return('Failed to create bet')
        }
    } catch (error) {
        console.error('An error occurred:', error);
        return('An error occurred:', error)
    }
};

export default createBet;