import React from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';

const InputForm = ({ handleSubmit, data, handleInputChange, formGroups }) => {
  return (
    <Form onSubmit={handleSubmit}>
      {formGroups.map((group, index) => (
        <InputGroupComponent
          key={index}
          label={group.label}
          type={group.type}
          name={group.name}
          value={data[group.name]}
          onChange={handleInputChange}
          options={group.options}
          disabled={group.disabled}
        />
      ))}
      {/* Submit button */}
      <Button variant="primary" type="submit">
        Create Bet
      </Button>
    </Form>
  );
};

const InputGroupComponent = ({ label, type, name, value, onChange, options, disabled }) => {
  return (
    <Form.Group>
      <InputGroup size="sm">
        <InputGroup.Text style={{ fontSize: '.75rem' }}>{label}:</InputGroup.Text>
        {type === 'select' ? (
          <Form.Control as="select" name={name} value={value} onChange={onChange} disabled={disabled}>
            {options && options.length > 0 ? (
              options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))
            ) : (
              <option value="">No options available</option>
            )}
          </Form.Control>
        ) : (
          <Form.Control type={type} name={name} value={value} onChange={onChange} />
        )}
      </InputGroup>
    </Form.Group>
  );
};


export default InputForm;




 