import React, { useEffect, useState } from "react";
import getModelDate from "../../MongoDb/GetModelDate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './Schedule.css';
import StatsMatch from "../stats";

export default function Model() {
  const footballstart = '2024-09-02';
  const startOfWeek1 = new Date(new Date(footballstart).setDate(new Date(footballstart).getDate() + 1));
  const today = new Date();
  const currentWeekNumber = Math.floor((today - startOfWeek1) / (7 * 24 * 60 * 60 * 1000)) + 1;
  
  const [loading, setLoading] = useState(false);

  const Loader = () => (
    <div className="loader"></div>
  );
  
  const getHomeAdv = (sport) => {
    if (sport === "NFL") {return 2.2;}
    if (sport === "CFB") {return 2.4;}
    if (sport === "NBA") {return 2.5;}
    if (sport === "CBB") {return 3.3;}
    if (sport === "MLB") {return 0.3;}
  }

  const [model, setModel] = useState({
    allBets: [],
    nflBets: [],
    cfbBets: [],
    nbaBets: [],
    cbbBets: [],
    nhlBets: [],
    mlbBets: [],
    soccerBets: [],
  });

  const [expandedGames, setExpandedGames] = useState({
    NFL: null,
    CFB: null,
    NBA: null,
    CBB: null,
    NHL: null,
    MLB: null,
    Soccer: null,
  });

  const [collapsedSections, setCollapsedSections] = useState({
    NFL: false,
    CFB: false,
    NBA: false,
    CBB: false,
    NHL: false,
    MLB: false,
    Soccer: false,
  });

  const [selectedDate, setSelectedDate] = useState(new Date());
  
  const reformatDate = (date_str) => {
    const date = new Date(date_str); // Parse the input date string into a Date object
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Get the month, add 1 (since months are 0-based), and format it to 2 digits
    const day = ('0' + date.getDate()).slice(-2); // Get the day, format it to 2 digits
    const year = date.getFullYear(); // Get the year
    return `${month}-${day}-${year}`; // Return the formatted date
  };

  useEffect(() => {
    const formatDate = (date) => {
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const year = String(date.getFullYear()).slice(-2);
      return `${month}/${day}/${year}`;
    };

    const fetchModelData = async () => {
      try {
        setLoading(true);
        const bets = await getModelDate(reformatDate(selectedDate));
        const sortedBets = bets.sort((a, b) => new Date(a.Time) - new Date(b.Time));
        setLoading(false);
        const filteredBets = sortedBets.filter(item => {
          const betDate = new Date(item.Time);
          return formatDate(betDate) === formatDate(selectedDate);


        });

        setModel({
          allBets: filteredBets,
          nflBets: filteredBets.filter(item => item.Sport === "NFL"),
          cfbBets: filteredBets.filter(item => item.Sport === "CFB"),
          nbaBets: filteredBets.filter(item => item.Sport === "NBA"),
          cbbBets: filteredBets.filter(item => item.Sport === "CBB"),
          nhlBets: filteredBets.filter(item => item.Sport === "NHL"),
          mlbBets: filteredBets.filter(item => item.Sport === "MLB"),
          soccerBets: filteredBets.filter(item => item.Sport === "Soccer"),
        });
      } catch (error) {
        console.error('Failed to get model data:', error);
      }
    };

    fetchModelData();
  }, [selectedDate]);  // Re-fetch data when the selected date changes

  const handleGameClick = (index, section) => {
    setExpandedGames(prevState => ({
      ...prevState,
      [section]: prevState[section] === index ? null : index
    }));
  };

  const handleToggleSection = (section) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const getRankSuffix = (rank) => {
    if (rank === undefined || rank === null) {
      return "-";
    }
    
    if (rank % 10 === 1 && rank !== 11) {
      return rank + 'st';
    } else if (rank % 10 === 2 && rank !== 12) {
      return rank + 'nd';
    } else if (rank % 10 === 3 && rank !== 13) {
      return rank + 'rd';
    } else {
      return rank + 'th';
    }
  };
  

  const getFormattedTime = (time) => {
    const date = new Date(time); // Parse the date
    const day = date.toLocaleDateString("en-US", { weekday: "short" }); // Get day of the week, e.g., "Wed"
    const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Add leading zero to minutes
    const ampm = date.getHours() < 12 ? "AM" : "PM"; // AM or PM
    return `${hours}:${minutes} ${ampm}`;
};

const getRecord = (item, loc) => {
  const sport = item.Sport;
  let stat = "";

  if (sport === "NFL" || sport === "CFB") {
    stat = item["Win-Loss Record_record_" + loc] || "NA";
  } else if (sport === "NHL") {
    stat = `${item["W_" + loc]}-${item["L_" + loc]}-${item["OT_" + loc]} (${getRankSuffix(item["Rk_" + loc])})`;
  } else if (sport === "NBA" || sport === "CBB") {
    stat = item["W-L_" + loc]  || "NA";
  } else if (sport === "Soccer") {
    stat = `${item["won_" + loc]}-${item["lost_" + loc]}-${item["drawn_" + loc]} (${getRankSuffix(item["rank_" + loc])})`;
  } else if (sport === "MLB") {
    stat = `${item["won_" + loc]}-${item["lost_" + loc]}`;
  }else {
    stat = "Sport not recognized";
  }
  return stat;
};

const getGoalieConference = (item, loc) => {
  const sport = item.Sport;
  let stat = "";
  let location = "";
  if (loc === 'y'){
    location = 'Away'
  }else if (loc === 'x'){
    location = 'Home'
  }

  if (sport === "NFL" || sport === "CFB") {
    stat = item[location.toLowerCase() + '_conference'] || "NA";
  } else if (sport === "NHL") {
    stat = item[location + " Goalie (Blend GAA)"] || "NA";
  } else if (sport === "NBA" || sport === "CBB") {
    stat = item["Conference_" + loc] || "NA";
  } else if (sport === "Soccer") {
    stat = item["competition"]?.["name"] || "NA";
  } else if (sport === "MLB") {
    stat = item[location + " Pitcher (WL ERA)"];
    if (stat && stat.includes("(")) {
      const [beforeParenthesis, afterParenthesis] = stat.split("(");
      stat = (
        <>
          {beforeParenthesis.trim()}
          <br />
          {item[[location.toLowerCase()] + "_pitcher_link"]?.[0] || "NA"}HP ({afterParenthesis.trim()}
        </>
      );
    }
  }else {
    stat = "Sport not recognized";
  }
  return stat;
};

const getPts = (item, type, loc) => {
  const sport = item.Sport;
  let stat = "";
  let percentage = 0

  if (sport === "NFL" || sport === "CFB" || sport === "NBA") {
    const value = parseFloat(item["2024_pts"]?.[type + "_" + loc]);
    stat = `${(isNaN(parseFloat(item["2024_pts"]?.[type + "_" + loc])) ? "NA" : parseFloat(item["2024_pts"]?.[type + "_" + loc]).toFixed(1))} (${getRankSuffix(item["Rank_pts"]?.[type + "_" + loc]) || "NA"})`;
    percentage = isNaN(value) ? 0 : Math.min((value / 100) * 100, 100);
  } else if (sport === "NHL") {
    stat = item["2024_pts"]?.[type + "_" + loc] || "NA";
  } else if (sport === "CBB") {
    stat = `${(isNaN(parseFloat(item["2024_pts"]?.[type + "_" + loc])) ? "NA" : parseFloat(item["2024_pts"]?.[type + "_" + loc]).toFixed(1))} (${getRankSuffix(item["Rank_pts"]?.[type + "_" + loc]) || "NA"})`;
  } else if (sport === "Soccer") {
    stat = `${parseFloat(item["2024_pts"]?.[type + "_" + loc]).toFixed(1) || "NA"}`;
  } else if (sport === "MLB") {
    stat = `${(isNaN(parseFloat(item["2024_pts"]?.[type + "_" + loc])) ? "NA" : parseFloat(item["2024_pts"]?.[type + "_" + loc]).toFixed(1))} (${getRankSuffix(item["Rank_pts"]?.[type + "_" + loc]) || "NA"})`;
  }else {
    stat = "Sport not recognized";
  }
  return stat;
};

const getPassShoot = (item, type, loc) => {
  const sport = item.Sport;
  let stat = "";

  if (sport === "NFL" || sport === "CFB") {
    stat = `${(isNaN(parseFloat(item["2024_pass"]?.[type + "_" + loc])) ? "NA" : parseFloat(item["2024_pass"]?.[type + "_" + loc]).toFixed(1))} (${getRankSuffix(item["Rank_pass"]?.[type + "_" + loc]) || "NA"})`;
  } else if (sport === "NHL") {
    stat = `${isNaN(item["SF_" + loc] / item["GP_" + loc]) ? "NA" : parseFloat(item["SF_" + loc] / item["GP_" + loc]).toFixed(1)} (${item["SH%-A_" + loc] || "NA"})`;
  } else if (sport === "NBA") {
    stat = `${(isNaN(parseFloat(item["2024_shooting"]?.[type + "_" + loc])) ? "" : (parseFloat(item["2024_shooting"]?.[type + "_" + loc]) * 100).toFixed(1))}% (${getRankSuffix(item["Rank_shooting"]?.[type + "_" + loc]) || "NA"})`;
  } else if (sport === "CBB") {
    stat = `${(isNaN(parseFloat(item["2024_shooting"]?.[type + "_" + loc])) ? "" : (parseFloat(item["2024_shooting"]?.[type + "_" + loc]) * 100).toFixed(1))}% (${getRankSuffix(item["Rank_shooting"]?.[type + "_" + loc]) || "NA"})`;
  } else if (sport === "Soccer") {
    stat = "";
  } else if (sport === "MLB") {
    stat = "";
  } else {
    stat = "Sport not recognized";
  }
  return stat;
};

const getRushEffPP = (item, type, loc) => {
  const sport = item.Sport;
  let stat = "";
  let location = "";
  let olocation = "";
  if (loc === 'y'){
    location = 'away'
    olocation = 'home'
  }else if (loc === 'x'){
    location = 'home'
    olocation = 'away'
  }

  if (sport === "NFL" || sport === "CFB") {
    stat = `${(isNaN(parseFloat(item["2024_ru"]?.[type + "_" + loc])) ? "NA" : parseFloat(item["2024_ru"]?.[type + "_" + loc]).toFixed(1))} (${getRankSuffix(item["Rank_ru"]?.[type + "_" + loc]) || "NA"})`;
  } else if (sport === "NHL") {
    stat = item["ADJ PP%_" + loc] || "NA";
  } else if (sport === "NBA") {
    stat = `${(isNaN(parseFloat(item["2024_defeff"]?.[type + "_" + loc])) ? "" : parseFloat(item["2024_defeff"]?.[type + "_" + loc]).toFixed(1))} (${getRankSuffix(item["Rank_defeff"]?.[type + "_" + loc]) || "NA"})`;
  } else if (sport === "CBB") {
    stat = `${(isNaN(parseFloat(item["2024_defeff"]?.[type + "_" + loc])) ? "" : parseFloat(item["2024_defeff"]?.[type + "_" + loc]).toFixed(1))} (${getRankSuffix(item["Rank_defeff"]?.[type + "_" + loc]) || "NA"})`;
  } else if (sport === "Soccer") {
    stat = "";
  } else if (sport === "MLB") {
    if (item[[olocation] + "_pitcher_link"] && item[[olocation] + "_pitcher_link"] === "Right") { 
      stat = item["vsright_Avg_" + [loc]] + " (RHP)";
    } else if (item[[olocation] + "_pitcher_link"] && item[[olocation] + "_pitcher_link"] === "Left") { 
      stat = item["vsleft_Avg_" + [loc]] + " (LHP)"
    }

  } else {
    stat = "Sport not recognized";
  }
  return stat;
};

const getPenPacePK = (item, type, loc) => {
  const sport = item.Sport;
  let stat = "";

  if (sport === "NFL" || sport === "CFB") {
    stat = `${(isNaN(parseFloat(item["2024_pen"]?.["yrds_" + loc])) ? "NA" : parseFloat(item["2024_pen"]?.["yrds_" + loc]).toFixed(1))} (${getRankSuffix(item["Rank_pen"]?.["yrds_" + loc]) || "NA"})`;
  } else if (sport === "NHL") {
    stat = item["ADJ PK%_" + loc] || "NA";
  } else if (sport === "NBA") {
    stat = `${(isNaN(parseFloat(item["2024_pace"]?.[type + "_" + loc])) ? "NA" : parseFloat(item["2024_pace"]?.[type + "_" + loc]).toFixed(1))} (${getRankSuffix(item["Rank_pace"]?.[type + "_" + loc]) || "NA"})`;
  } else if (sport === "CBB") {
    stat = `${(isNaN(parseFloat(item["2024_pace"]?.[type + "_" + loc])) ? "NA" : parseFloat(item["2024_pace"]?.[type + "_" + loc]).toFixed(1))} (${getRankSuffix(item["Rank_pace"]?.[type + "_" + loc]) || "NA"})`;
  } else if (sport === "Soccer") {
    stat = "";
  } else if (sport === "MLB") {
    stat = parseFloat(item["pitch_mult_" + loc]).toFixed(1) || "NA";
  } else {
    stat = "Sport not recognized";
  }
  return stat;
};

const getGiveMargFO = (item, type, loc) => {
  const sport = item.Sport;
  let stat = "";

  if (sport === "NFL" || sport === "CFB") {
    stat = `${(isNaN(parseFloat(item["2024_gvwys_" + loc])) ? "NA" : parseFloat(item["2024_gvwys_" + loc]).toFixed(1))} (${getRankSuffix(item["Rank_gvwys_" + loc]) || "NA"})`;
  } else if (sport === "NHL") {
    stat = item["FO%_" + loc] || "NA";
  } else if (sport === "NBA") {
    stat = `${(isNaN(parseFloat(item["2024_steals"]?.[type + "_" + loc])) ? "NA" : parseFloat(item["2024_steals"]?.[type + "_" + loc]).toFixed(1))} (${getRankSuffix(item["Rank_steals"]?.[type + "_" + loc]) || "NA"})`;
  } else if (sport === "CBB") {
    stat = `${(isNaN(parseFloat(item["2024_steals"]?.[type + "_" + loc])) ? "NA" : parseFloat(item["2024_steals"]?.[type + "_" + loc]).toFixed(1))} (${getRankSuffix(item["Rank_steals"]?.[type + "_" + loc]) || "NA"})`;
  } else if (sport === "Soccer") {
    stat = "";
  } else if (sport === "MLB") {
    stat = "";
  } else {
    stat = "Sport not recognized";
  }
  return stat;
};

const getTake = (item, type, loc) => {
  const sport = item.Sport;
  let stat = "";

  if (sport === "NFL" || sport === "CFB") {
    stat = `${(isNaN(parseFloat(item["2024_tkwys_" + loc])) ? "NA" : parseFloat(item["2024_tkwys_" + loc]).toFixed(1))} (${getRankSuffix(item["Rank_tkwys_" + loc]) || "NA"})`;
  } else if (sport === "NHL") {
    stat = `${isNaN(item["HITS_" + loc] / item["GP_" + loc]) ? "NA" : parseFloat(item["HITS_" + loc] / item["GP_" + loc]).toFixed(1)} (${isNaN(item["HITS-D/GP_" + loc]) ? "NA" : parseFloat(item["HITS-D/GP_" + loc]).toFixed(1)})`;
  } else if (sport === "NBA") {
    stat = `${(isNaN(parseFloat(item["2024_margin"]?.[type + "_" + loc])) ? "NA" : parseFloat(item["2024_margin"]?.[type + "_" + loc]).toFixed(1))} (${getRankSuffix(item["Rank_margin"]?.[type + "_" + loc]) || "NA"})`;
  } else if (sport === "CBB") {
    stat = `${(isNaN(parseFloat(item["2024_margin"]?.[type + "_" + loc])) ? "NA" : parseFloat(item["2024_margin"]?.[type + "_" + loc]).toFixed(1))} (${getRankSuffix(item["Rank_margin"]?.[type + "_" + loc]) || "NA"})`;
  } else if (sport === "Soccer") {
    stat = "";
  } else if (sport === "MLB") {
    stat = "";
  } else {
    stat = "Sport not recognized";
  }
  return stat;
};

const getInj = (item, loc) => {
  const sport = item.Sport;
  let stat = "";

  if (sport === "NFL" || sport === "CFB" || sport === "NBA") {
    stat = `${isNaN(item["QB_Injury_DYAR"]?.[loc]) || isNaN(item["RB_Injury_DYAR"]?.[loc]) || isNaN(item["WR_Injury_DYAR"]?.[loc]) ? '-' : -parseInt((item["QB_Injury_DYAR"]?.[loc] + item["RB_Injury_DYAR"]?.[loc] + item["WR_Injury_DYAR"]?.[loc]))}`;
  } else if (sport === "NHL") {
    stat = "-";
  } else if (sport === "CBB") {
    stat = "-";
  } else if (sport === "Soccer") {
    stat = "-";
  } else if (sport === "MLB") {
    stat = "-";
  } else {
    stat = "Sport not recognized";
  }
  return stat;
};

const getRankString = (item, loc) => {
  const sport = item.Sport;
  let stat = "";

  if (sport === "CBB" || sport === "CFB") {
    stat = `${(isNaN(parseFloat(item["2024_gvwys_" + loc])) ? "NA" : parseFloat(item["2024_gvwys_" + loc]).toFixed(1))} (${getRankSuffix(item["Rank_gvwys_" + loc]) || "NA"})`;
  } else if (sport === "NHL" || sport === "NFL" || sport === "NBA" || sport === "Soccer") {
    stat = "-";
  } else if (sport === "MLB") {
    stat = `-`;
  } else {
    stat = "Sport not recognized";
  }
  return stat;
};

const getBye = (item, type, loc) => {
  const sport = item.Sport;
  let stat = "";
  if (sport === "NFL" || sport === "CFB") {
    stat = item["Bye_" + loc] || "-";
    if (item["Bye_" + loc] + 1 === item.Week)
      stat = stat + " (" + item["ATS Record_bye_ats_" + loc] + " ATS) (" + item["Over Record_bye_ou_" + loc] + " OU)"
  } else if (sport === "NHL") {
    stat = "";
  } else if (sport === "NBA") {
    stat = item["2024_lead"]?.[type + "_" + loc];
  } else if (sport === "CBB") {
    stat = "";
  } else if (sport === "Soccer") {
    stat = "";
  } else if (sport === "MLB") {
    stat = "";
  } else {
    stat = "Sport not recognized";
  }
  return stat;
};

const getATS = (item, loc) => {
  const sport = item.Sport;
  let stat = "";
  if (sport === "NFL" || sport === "CFB" || sport === "NBA" || sport === "CBB") {
    stat = item["ATS Record_ats_" + loc] + " (" + item["ATS +/-_ats_" + loc] + ") ";
  } else if (sport === "Soccer") {
    stat = "-";
  } else if (sport === "MLB") {
    stat = `-`;
  } else {
    stat = "Sport not recognized";
  }
  return stat;
};

const getRecordAs = (item, loc) => {
  const sport = item.Sport;
  let stat = "";

  if (sport === "NFL" || sport === "CFB") {

    if (item["ML_Home"] < 0)
      if  (loc === "x")
        stat = item["ATS Record_home_fav_" + loc] + " (" + item["ATS +/-_home_fav_" + loc] + ") " + " (home fav)" || "-";
      else stat = item["ATS Record_away_dog_" + loc] + " (" + item["ATS +/-_away_dog_" + loc] + ") " + " (away dog)" || "-";
    else
      if  (loc === "x")
        stat = item["ATS Record_home_dog_" + loc] + " (" + item["ATS +/-_home_dog_" + loc] + ") " + " (home dog)" || "-";
      else stat = item["ATS Record_away_fav_" + loc] + " (" + item["ATS +/-_away_fav_" + loc] + ") " + " (away fav)"  || "-";
  } else if (sport === "NHL") {
    stat = "-";
  } else if (sport === "NBA" || sport === "CBB") {
    if (item["ML_Home"] < 0)
      if  (loc === "x")
        stat = item["ATS Record_home_fav_" + loc] + " (" + item["ATS +/-_home_fav_" + loc] + ") " + " (home fav)" || "-";
      else stat = item["ATS Record_away_dog_" + loc] + " (" + item["ATS +/-_away_dog_" + loc] + ") " + " (away dog)" || "-";
    else
      if  (loc === "x")
        stat = item["ATS Record_home_dog_" + loc] + " (" + item["ATS +/-_home_dog_" + loc] + ") " + " (home dog)" || "-";
      else stat = item["ATS Record_away_fav_" + loc] + " (" + item["ATS +/-_away_fav_" + loc] + ") " + " (away fav)"  || "-";
  } else if (sport === "Soccer") {
    stat = "";
  } else if (sport === "MLB") {
    if (item["ML_Home"] < 0)
      if  (loc === "x")
        stat = item["Win-Loss Record_home_fav_" + loc] + " (" + item["Run Line +/-_home_fav_" + loc] + ") " + " (home fav)" || "-";
      else stat = item["Win-Loss Record_away_dog_" + loc] + " (" + item["Run Line +/-_away_dog_" + loc] + ") " + " (away dog)" || "-";
    else
      if  (loc === "x")
        stat = item["Win-Loss Record_home_dog_" + loc] + " (" + item["Run Line +/-_home_dog_" + loc] + ") " + " (home dog)" || "-";
      else stat = item["Win-Loss Record_away_fav_" + loc] + " (" + item["Run Line +/-_away_fav_" + loc] + ") " + " (away fav)"  || "-";
  }else {
    stat = "Sport not recognized";
  }
  return stat;
};

const getOURecord = (item, loc) => {
  const sport = item.Sport;
  let stat = "";

  if (sport === "NFL" || sport === "CFB" || sport === "NBA" || sport === "CBB" || sport === "MLB") {
    stat = item["Over Record_over_under_" + loc] + " (" + item["Total +/-_over_under_" + loc] + ")" || "-";
  } else if (sport === "NHL") {
    stat = "-";
  } else if (sport === "Soccer") {
    stat = "-";
  } else {
    stat = "Sport not recognized";
  }
  return stat;
};

const getScore = (item, loc) => {
  const sport = item.Sport;
  let stat = "";

  if (sport === "NHL" || sport === "CBB" || sport === "Soccer" || sport === "NFL" || sport === "CFB" || sport === "NBA" || sport === "MLB") {
    const key = `score_${loc}`;
    const score = item[key];

    if (score === undefined || score === null || Number.isNaN(score) || item.status === "NS") {
      console.error(`Invalid score for key '${key}'`);
      stat = "-";
    } else {
      stat = score;
    }}
  else {
    stat = "-";
  }
  return stat;
};

const getLocation = (item) => {
  const sport = item.Sport;
  let stat = "";

  if (sport === "Soccer") {
    const location = item["location"];
    const homeLocation = item["team"]?.["stadium_x"] || "NA";
    if (location === undefined || location === null || location === "") {
      console.error(`Invalid location data for key '${item.Home}'`);
      stat = "-";
    } else {
      if (location === homeLocation){
        stat = location;
    } 
    else {stat = location + " (Neutral)"}
    }
  } else if (sport === "MLB") {
    const location = item["venue"];

    const weather = item["weather"] + ", " + item["wind"];
    stat = (
      <>
        {location}
        <br />
        {weather}
      </>
    );

  }else {
    stat = "-";
  }
  return stat;
};

const getH2H = (item, loc) => {
  const sport = item.Sport;
  let stat = "";

  if (sport === "Soccer") {
    const h2h = item["h2h"];
    if (h2h === undefined || h2h === null || h2h === "") {
      console.error(`Invalid h2h data for key '${item.Home}'`);
      stat = "-";
    } else {
      stat = h2h;
    }}
  else {
    stat = "-";
  }
  return stat;
};

  const renderGridRows = (betData, section) => {
    if (loading) {return <div className="loader"></div>;}
    return betData.map((item, index) => {
      const statistics = [
        { type: "Pts For", home: getPts(item, "for", "x"), away: getPts(item, "for", "y") },
        { type: "Pts Against", home: getPts(item, "ag", "x"), away: getPts(item, "ag", "y") },

        { 
          type: item.Sport && item.Sport.includes("NHL") 
            ? "Shots (% For)" 
            : item.Sport.includes("NBA") || item.Sport.includes("CBB") 
            ? "Shooting" 
            : item.Sport.includes("NFL") || item.Sport.includes("CFB") 
            ? "Pass Yards" 
            : "",
          home: getPassShoot(item, "for", "x"), 
          away: getPassShoot(item, "for", "y")
        },

        {
          type: item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB")) 
            ? "Pass Yds Ag" 
            : "",
          home: item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB")) 
            ? getPassShoot(item, "ag", "x") 
            : "",
          away: item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB")) 
            ? getPassShoot(item, "ag", "y") 
            : ""
        },
        
        {
          type: item.Sport && item.Sport.includes("NHL")
            ? "PP"
            : item.Sport.includes("NBA") || item.Sport.includes("CBB")
            ? "Def Eff"
            : item.Sport.includes("NFL") || item.Sport.includes("CFB")
            ? "Rush Yds"
            : item.Sport.includes("MLB")
            ? "Avg vs Hand"
            : "",
          home: getRushEffPP(item, "for", "x"),
          away: getRushEffPP(item, "for", "y")
        },
        {
          type: item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB")) 
            ? "Rush Yds Ag" 
            : "",
          home: item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB")) 
            ? getRushEffPP(item, "ag", "x") 
            : "",
          away: item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB")) 
            ? getRushEffPP(item, "ag", "y") 
            : ""
        },

        {
          type: item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB")) 
            ? "Rush Eff" 
            : "",
          home: item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB")) 
            ? getRushEffPP(item, "ag", "x")
            : "",
          away: item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB")) 
            ? getRushEffPP(item, "ag", "y")
            : ""
        },

        {
          type: item.Sport && item.Sport.includes("NHL")
            ? "PK"
            : item.Sport.includes("NBA") || item.Sport.includes("CBB")
            ? "Pace"
            : item.Sport.includes("NFL") || item.Sport.includes("CFB")
            ? "Penalty Yds"
            : item.Sport.includes("MLB")
            ? "Mult vs Hand"
            :"",
          home: getPenPacePK(item, "for", "x"),
          away: getPenPacePK(item, "for", "y")
        },

        {
          type: item.Sport && item.Sport.includes("NHL")
            ? "Faceoff"
            : item.Sport.includes("NBA") || item.Sport.includes("CBB")
            ? "Steals"
            : item.Sport.includes("NFL") || item.Sport.includes("CFB")
            ? "Giveaways"
            : "",
          home: getGiveMargFO(item, "for", "x"),
          away: getGiveMargFO(item, "for", "y")
        },

        {
          type: item.Sport && item.Sport.includes("NHL")
            ? "Hits (Diff)"
            : item.Sport.includes("NBA") || item.Sport.includes("CBB")
            ? "Margin"
            : item.Sport.includes("NFL") || item.Sport.includes("CFB")
            ? "Takeaways"
            : "",
          home: getTake(item, "for", "x"),
          away: getTake(item, "for", "y")
        },


        {
          type: item.Sport.includes("NBA") || item.Sport.includes("CBB")
            ? "Avg Lead"
            : item.Sport.includes("NFL") || item.Sport.includes("CFB")
            ? "Bye Week"
            :"",
          home: getBye(item, "for", "x"),
          away: getBye(item, "for", "y")
        },

        { 
          type: item.Sport.includes("MLB") || item.Sport.includes("NHL")
            ? ""
            : "Power Rating", 
          home: item.Sport.includes("MLB") || item.Sport.includes("NHL")
          ? ""
          : `${item.Rating_FPI_x} ${
                (parseFloat(item.Rating_FPI_x) + getHomeAdv(item.Sport) - parseFloat(item.Rating_FPI_y)) > 0 
                  ? `(${(parseFloat(item.Rating_FPI_x) + getHomeAdv(item.Sport) - parseFloat(item.Rating_FPI_y)).toFixed(1)})` 
                  : ""
              }`,
          away: item.Sport.includes("MLB") || item.Sport.includes("NHL")
          ? ""
          : `${item.Rating_FPI_y} ${
                (parseFloat(item.Rating_FPI_y) - getHomeAdv(item.Sport) - parseFloat(item.Rating_FPI_x)) > 0 
                  ? `(${(parseFloat(item.Rating_FPI_y) - getHomeAdv(item.Sport) - parseFloat(item.Rating_FPI_x)).toFixed(1)})` 
                  : ""
              }`,
        }

      ];
      const isExpanded = expandedGames[section] === index;
      // Calculate the score difference and check against the spread
      const validStatuses = ["FT", "AOT", "AP"];
      const awayScore = parseFloat(getScore(item, 'y')) || 0;
      const homeScore = parseFloat(getScore(item, 'x')) || 0;
      const spread = parseFloat(item.Spread_home) || 0;
      
      // Ensure the game status is valid and scores are non-zero
      const isValidGame = validStatuses.includes(item.status) && (homeScore !== 0 || awayScore !== 0);
      
      // Determine home winner and loser
      const homeWinner = isValidGame && parseFloat(item.Value_Home) > 0 && (
        spread > 0 
          ? spread < (homeScore - awayScore) 
          : Math.abs(spread) < (homeScore - awayScore)
      );
      
      const homeLoser = isValidGame && parseFloat(item.Value_Home) > 0 && (
        spread > 0 
          ? spread > (homeScore - awayScore) 
          : Math.abs(spread) > (homeScore - awayScore)
      );
      
      // Determine away winner and loser
      const awayWinner = isValidGame && parseFloat(item.Value_Away) > 0 && (
        spread < 0
          ? spread < (awayScore - homeScore)
          : Math.abs(spread) < (awayScore - homeScore)
        );
      
      const awayLoser = isValidGame && parseFloat(item.Value_Away) > 0 && (
        spread < 0 
          ? spread > (awayScore - homeScore) 
          : Math.abs(spread) > (awayScore - homeScore)
      );
      
      // Determine over/under winners and losers
      const overWinner = isValidGame && typeof item.Proj_Total === 'string' && item.Proj_Total.includes("Over") && (awayScore + homeScore) > item["O/U"];
      const overLoser = isValidGame && typeof item.Proj_Total === 'string' && item.Proj_Total.includes("Over") && (awayScore + homeScore) < item["O/U"];
      const underWinner = isValidGame && typeof item.Proj_Total === 'string' && item.Proj_Total.includes("Under") && (awayScore + homeScore) < item["O/U"];
      const underLoser = isValidGame && typeof item.Proj_Total === 'string' && item.Proj_Total.includes("Under") && (awayScore + homeScore) > item["O/U"];
      
      // Determine overall winner and loser
      const winner = (
        [homeWinner, awayWinner, overWinner, underWinner].filter(Boolean).length === 1 &&
        ![homeLoser, awayLoser, overLoser, underLoser].some(Boolean)
      );
      
      const loser = (
        [homeLoser, awayLoser, overLoser, underLoser].filter(Boolean).length === 1 &&
        ![homeWinner, awayWinner, overWinner, underWinner].some(Boolean)
      );
      
      // Determine double winners and losers
      const dubwinner = (
        [homeWinner, awayWinner, overWinner, underWinner].filter(Boolean).length > 1
      );
      
      const dubloser = (
        [homeLoser, awayLoser, overLoser, underLoser].filter(Boolean).length > 1
      );
    
      return (
        <div
            key={index}
            className="grid-item-container"
            style={{
              background: 
                    winner
                  ? 'radial-gradient(ellipse at bottom,limegreen, #0e1e29)'
                  : loser
                  ? 'radial-gradient(ellipse at bottom, indianred, #0e1e29)'
                  : dubwinner
                  ? 'radial-gradient(ellipse at bottom, darkgreen, #0e1e29)'
                  : dubloser
                  ? 'radial-gradient(ellipse at bottom, maroon, #0e1e29)'
                  : '#0e1e29',
          }}
        >

          <div className="match-container" onClick={() => handleGameClick(index, section)}>
            <div className="time">{getFormattedTime(item.Time)}</div>
            <div className="teams">
              <div className="away-team">
                {item.Logo_y ? (<><img src={item.Logo_y} alt="" className="team-logo" />{item.Away}</>) : (item.Away)}
              </div>
              <div className="home-team">
                {item.Logo_x ? (<><img src={item.Logo_x} alt="" className="team-logo" />{item.Home}</>) : (item.Home)}
              </div>
            </div>
            <div className="projections">
              <div className="values">
                {item.Value_Away > 0 ? <span className="gold-dot"></span> : <div>&nbsp;</div>}
                {item.Value_Home > 0 ? <span className="gold-dot"></span> : <div>&nbsp;</div>}
              </div>
              <div className="actual-scores">
                <div className="away-score">{getScore(item, 'y')}</div>
                <div className="home-score">{getScore(item, 'x')}</div>
              </div>
              <div className="actual-scores">
                <div className="status">{item.status && item.status !== "Not Started" && item.status !== "TIMED" ? item.status : ""}</div>
              </div>
              <div className="projected-scores">
                <div className="away-proj">{Math.round(parseFloat(item.Away_Proj))}</div>
                <div className="home-proj">{Math.round(parseFloat(item.Home_Proj))}</div>
              </div>
              <div className="ml">
                <div className="away-ml">{item.ML_Away}</div>
                <div className="home-ml">{item.ML_Home}</div>
              </div>
              <div className="values">
                <div>{item.Sport && item.Sport.includes("Soccer")? parseFloat(item.Away_Proj + item.Home_Proj).toFixed(1): parseInt(item.Spread_home) < 0 ? item["O/U"] : item.Spread_home ? -item.Spread_home : ""}</div>
                <div>{item.Sport && item.Sport.includes("Soccer")? item.ML_Draw : parseInt(item.Spread_home) < 0 ? item.Spread_home ? item.Spread_home : "" : item["O/U"]}</div>
              </div>
              <div className="values">
                {item.Proj_Total && item.Proj_Total.includes("Over") ? <span className="gold-dot"></span> : <div>&nbsp;</div>}
                {item.Proj_Total && item.Proj_Total.includes("Under") ? <span className="gold-dot"></span> : <div>&nbsp;</div>}
              </div>
            </div>
          </div>
          {isExpanded && (
            <div className="expanded-stats">
              <div className="team-expanded-stats">
                <div>{item.Logo_y && item.Logo_y.includes("https") ? (<img src={item.Logo_y} alt={item.Away} className="team-logo2" />) : (<><br/><br/></>)}</div>
                <div style={{ textDecoration: 'underline' }}>{item.Away ? item.Away: '-'}</div>
                <div>{getRecord(item, 'y')}</div>
                <div>{getGoalieConference(item, 'y')}</div>
                <div>{item.Proj_ML_Away ? item.Proj_ML_Away: '-'}</div>
                {/* <div>{getPts(item, 'for', 'y').stat}</div> */}
                {/* <div>{getPts(item, 'ag', 'y').stat}</div> */}
                {/* <div>{getPassShoot(item, 'for', 'y')}</div>
                <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB")) ? getPassShoot(item, 'ag', 'y') : ""}</div>
                <div>{getRushEffPP(item, 'for', 'y')}</div>
                <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB")) ? getRushEffPP(item, 'ag', 'y') : ""}</div>    
                <div>{getPenPacePK(item, 'for', 'y')}</div>           
                <div>{getGiveMargFO(item, 'for', 'y')}</div>
                <div>{getTake(item, 'for', 'y')}</div> */}
                {/* <div>{item.Rating_FPI_y ? item.Rating_FPI_y + ((parseFloat(item.Rating_FPI_y) - getHomeAdv(item.Sport) - parseFloat(item.Rating_FPI_x)) > 0 ? " (" + parseFloat(parseFloat(item.Rating_FPI_y) - getHomeAdv(item.Sport) - parseFloat(item.Rating_FPI_x)).toFixed(1) + ")": ""): '-'}</div> */}
                {/* <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB") || item.Sport.includes("NBA") || item.Sport.includes("CBB")) ? getBye(item, 'for', 'y') : ""}</div>  */}
                <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB") || item.Sport.includes("NBA") || item.Sport.includes("CBB")) ? getATS(item, 'y') : ""}</div> 
                <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB") || item.Sport.includes("NBA") || item.Sport.includes("CBB") || item.Sport.includes("MLB")) ? getRecordAs(item, 'y') : ""}</div> 
                <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB") || item.Sport.includes("NBA") || item.Sport.includes("CBB") || item.Sport.includes("MLB")) ? getOURecord(item, 'y') : ""}</div> 
                <div>{getInj(item, 'y')}</div>
              </div>
              <div className="team-expanded-header">
                <div>{item.status && item.status !== "Not Started" && item.status !== "TIMED" ? <><span className="score-display">{getScore(item, 'y') + " : " + getScore(item, 'x')}</span><br /><div style={{ lineHeight: '1.15em', color: '#c7c7b1'  }}>{item.status}</div></> : (<div style={{ lineHeight: '1.9' }}><><br /><br /></></div>)}</div>
                <div style={{ textDecoration: 'underline' }}>Team</div>
                <div>Record</div>
                <div>{item.Sport && item.Sport.includes("NHL") ? "Starting Goalie" : item.Sport.includes("MLB") ? <>Starting Pitcher<br /> Starter Rec ERA<br /></> : "Conference"}</div>
                <div>Proj ML</div>
                {/* <div>Pts For</div>  
                <div>Pts Against</div>  
                <div>{item.Sport && item.Sport.includes("NHL") ? "Shots (% Ag)" : item.Sport.includes("NBA") || item.Sport.includes("CBB") ? "Shooting" :  item.Sport.includes("NFL") || item.Sport.includes("CFB") ? "Pass Yds" : ""}</div> 
                <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB")) ? "Pass Yds Ag" : ""}</div>
                <div>{item.Sport && item.Sport.includes("NHL") ? "PP" : item.Sport.includes("NBA") || item.Sport.includes("CBB") ? "Def Eff" : item.Sport.includes("Soccer") || item.Sport.includes("MLB") ? "" :"Rush Yds"}</div>   
                <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB")) ? "Rush Yds Ag" : ""}</div>
                <div>{item.Sport && item.Sport.includes("NHL") ? "PK" : item.Sport.includes("NBA") || item.Sport.includes("CBB") ? "Pace" : item.Sport.includes("Soccer") || item.Sport.includes("MLB")? "" :"Penalty Yds"}</div> 
                <div>{item.Sport && item.Sport.includes("NHL") ? "Faceoff" : item.Sport.includes("NBA") || item.Sport.includes("CBB") ? "Steals" : item.Sport.includes("Soccer") || item.Sport.includes("MLB")? "" :"Giveaways"}</div>  
                <div>{item.Sport && item.Sport.includes("NHL") ? "Hits (Diff)" : item.Sport.includes("NBA") || item.Sport.includes("CBB") ? "Margin" : item.Sport.includes("Soccer") || item.Sport.includes("MLB")? "" :"Takeaways"}</div> */}
                {/* <div>PI</div> */} 
                {/* <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB")) ? "Bye Week" : (item.Sport.includes("NBA") || item.Sport.includes("CBB")) ? "Avg Lead" :""}</div>     */}
                <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB") || item.Sport.includes("NBA") || item.Sport.includes("CBB")) ? "ATS Record" : ""}</div>                     
                <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB") || item.Sport.includes("NBA") || item.Sport.includes("CBB") || item.Sport.includes("MLB"))? "ATS Rec" : ""}</div>       
                <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB") || item.Sport.includes("NBA") || item.Sport.includes("CBB") || item.Sport.includes("MLB")) ? "OU Rec" : ""}</div>
                <div>Injury</div>   
                <div><><br/>{getLocation(item)}</></div>
              </div>
              <div className="team-expanded-stats">
              <div>{item.Logo_x && item.Logo_x.includes("https") ? (<img src={item.Logo_x} alt={item.Home} className="team-logo2" />) : (<><br/><br/></>)}</div>
                <div style={{ textDecoration: 'underline' }}>{item.Home ? item.Home: '-'}</div>
                <div>{getRecord(item, 'x')}</div>
                <div>{getGoalieConference(item, 'x')}</div>
                <div>{item.Proj_ML_Home ? item.Proj_ML_Home: '-'}</div>
                {/* <div className="progress-bar-container">
                  <div
                    className="progress-bar"
                    style={{ width: `${getPts(item, 'for', 'y').percentage}%` }}
                  ></div>
                </div> */}
                {/* <div>{getPts(item, 'for', 'x').stat}</div> */}
                {/* <div>{getPts(item, 'ag', 'x').stat}</div>
                <div>{getPassShoot(item, 'for', 'x')}</div>
                <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB")) ? getPassShoot(item, 'ag', 'x') : ""}</div>
                <div>{getRushEffPP(item, 'for', 'x')}</div>
                <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB")) ? getRushEffPP(item, 'ag', 'x') : ""}</div>
                <div>{getPenPacePK(item, 'for', 'x')}</div> */}
                {/* <div>{getGiveMargFO(item, 'for', 'x')}</div>
                <div>{getTake(item, 'for', 'x')}</div> */}
                {/* <div>{item.Rating_FPI_x ? item.Rating_FPI_x + ((parseFloat(item.Rating_FPI_x) + getHomeAdv(item.Sport) - parseFloat(item.Rating_FPI_y)) > 0 ? " (" + parseFloat(parseFloat(item.Rating_FPI_x) + getHomeAdv(item.Sport) - parseFloat(item.Rating_FPI_y)).toFixed(1) + ")": ""): '-'}</div> */}
                {/* <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB") || item.Sport.includes("NBA") || item.Sport.includes("CBB")) ? getBye(item, 'for', 'x') : ""}</div>  */}
                <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB") || item.Sport.includes("NBA") || item.Sport.includes("CBB")) ? getATS(item, 'x') : ""}</div> 
                <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB") || item.Sport.includes("NBA") || item.Sport.includes("CBB") || item.Sport.includes("MLB")) ? getRecordAs(item, 'x') : ""}</div> 
                <div>{item.Sport && (item.Sport.includes("NFL") || item.Sport.includes("CFB") || item.Sport.includes("NBA") || item.Sport.includes("CBB") || item.Sport.includes("MLB")) ? getOURecord(item, 'x') : ""}</div> 
                <div>{getInj(item, 'x')}</div>
              </div>
            </div>
          )} 
          {isExpanded && (<div className="expanded-stats">                
          <div key={index} className="grid-item-container" style={{ border: "none" }}>
          <StatsMatch statistics={statistics} isDarkMode={false} />
      </div>
      </div> )}    
        </div>
      );
    });
  };

  return (
    <div className="bets-page">
      <div className="calendar-header">
        <h3>Select Date:</h3>
        <button onClick={() => setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)))}>▼</button>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="MM/dd/yy"
          className="date-picker"
        />
      <button onClick={() => setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)))}>▲</button>
      <button onClick={() => setSelectedDate(new Date(today))}>x</button>
      </div>
  
      {/* Render Sections */}
      {["MLB", "NBA", "NHL", "Soccer", "NFL", "CFB", "CBB"].map((section) => (
        <React.Fragment key={section}>
          <h3
            className="bet-header"
            onClick={() => handleToggleSection(section)}>
            {section} {collapsedSections[section] ? "▲" : "▼"}
          </h3>
          {!collapsedSections[section] && (
            <div className="grid-container">
              {renderGridRows(model?.[`${section.toLowerCase()}Bets`] || [], section)}
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}