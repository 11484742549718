import React, { useEffect, useState } from "react";
import getTodaysScores from "../../MongoDb/GetScores";
import { ButtonGroup, Button, Container, Row, Col } from "react-bootstrap";
import { useMobile } from "../MobileContext";
import './Scores.css'

export default function Scores() {
    const [scores, setScores] = useState([]);
    const isMobile = useMobile()

    useEffect(() => {
      const fetchScores = async () => {
        try {
          const scoresData = await getTodaysScores(); // Assuming getScores is a function that fetches scores
          console.log(scoresData);
          setScores(scoresData || []); // Use an empty array if scoresData is falsy (null or undefined)
        } catch (error) {
          console.error('Failed to get scores:', error);
        }
      };
  
      fetchScores();
    }, []);
    
    const formatToEST = (dateTimeString) => {
        const options = {
          timeZone: 'America/New_York', // Eastern Time Zone
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true, // Set to true for 12-hour clock format
        };
    
        return new Date(dateTimeString).toLocaleString('en-US', options);
    };

    return (
      <div>
        {scores && scores.length === 0 ? (
          <div> Scores Loading </div>
        ) : (
        <div style={{ color: 'white' }}>
            <h2>Game Scores</h2>
                <>
                  {isMobile ? (
                    <div className="bet-container-mobile">
                      {scores.map((game) => 
                        <div>
                          <GameScore {...game} />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="bet-container">
                      {scores.map((game) => 
                        <div>
                          <GameScore {...game} />
                        </div>
                      )}
                    </div>
                  )}
                </>
          </div>
        )}
      </div>
    );
  }
  

const ScoreCard = ({ date, game_id, sport, teams }) => {
  return (
    <div className="bet-title">
      <p>Date: {date}</p>
      <p>Game ID: {game_id}</p>
      <p>Sport: {sport}</p>
      <div>
        {teams.map((team, index) => (
          <div key={index}>
            <p>Team: {team.displayName}</p>
            <p>Home/Away: {team.homeAway}</p>
            <p>Score: {team.score}</p>
            <p>Winner: {team.winner ? 'Yes' : 'No'}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const GameScore = ({ date, game_id, sport, teams }) => {
  const isMobile = useMobile()
  let Result = null
  let Bet_Team = 'TEST'
  let spread_prefix = '+'
  let Spread = 5
  let Odds = -110
  let showresult = false
  let Risk_Amount = 100
  let To_Win_Amount = 150
  let Result_Profit = 0
  const result_color = Result === undefined || Result === null || Result === 'P' || Result === '' ? '#A4A4AA' : Result === 'W' ? '#00C358' : '#F32535';
  
  const homeTeam = teams.find((team) => team.homeAway === 'home');
  const awayTeam = teams.find((team) => team.homeAway === 'away');
  
  const gameDate = new Date(date);
  // Format the date to "hh:mm mm/dd/yy" in Eastern Time
  const formattedDate = gameDate.toLocaleString('en-US', {
    timeZone: 'America/New_York',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  });
  const formattedTime = gameDate.toLocaleString('en-US', {
    timeZone: 'America/New_York',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  });

  return (
    <div className="score-tile">
        <div className="bet-info" style={{gap: '0px'}}>
            <div className="bet-status">
                    <svg role="presentation" focusable="false" width="36" height="36" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <g fill={Result === '' | Result === undefined || Result === null || Result === 'P' ? '#A4A4AA': Result === 'W'? "#00C358" : "#F32535"}>
                        <g>
                        <g transform="translate(4 4)">
                            <rect opacity="0.3" width="16" height="16" rx="8"></rect>
                            <rect x="4" y="4" width="8" height="8" rx="4"></rect>
                        </g>
                        </g>
                    </g>
                    </svg>
            </div>
            {homeTeam.linescores && homeTeam.linescores.length > 0 ? (
              <Container fluid style={{ fontSize: '10px' }}>
                <Row>
                <Col className='col-6' style={{fontWeight:'bold', fontSize: isMobile ? 'x-small' : 'medium'}}> {Bet_Team} {spread_prefix}  {Spread} ({Odds})</Col>
                  {homeTeam.linescores.map((score, index) => (
                    <Col className='col-1' style={{paddingRight: '5px', paddingLeft: '5px', fontSize:'x-small'}}>
                      {index+1}
                    </Col>
                   ))}
                  <Col className='col-1' style={{paddingRight: '5px', paddingLeft: '5px', fontSize:'x-small'}}>
                    F
                  </Col>
                </Row>
                <Row>
                  <Col className='col-6' style={{fontFamily: 'Helvetica, Arial, sans-serif'}}>
                    {homeTeam.displayName} at
                  </Col>
                  {homeTeam.linescores.map((score, index) => (
                    <Col className='col-1' style={{paddingRight: '5px', paddingLeft: '5px', fontFamily: 'Helvetica, Arial, sans-serif', fontSize:'x-small', opacity:'.65'}}>
                      {score.value}
                    </Col>
                   ))}
                   <Col className='col-1' style={{paddingRight: '5px', paddingLeft: '5px', fontFamily: 'Helvetica, Arial, sans-serif', fontSize:'x-small', color: homeTeam.winner ? 'goldenrod' : 'inherit'}}>
                    {homeTeam.score}
                   </Col>
                   {/* <Col className='col-1' style={{paddingRight: '5px', paddingLeft: '5px'}}>
                     {homeTeam.winner ? 'W' : ''}
                  </Col> */}
                </Row>
                <Row>
                  <Col className='col-6' style={{fontFamily: 'Helvetica, Arial, sans-serif'}}>
                    {awayTeam.displayName}
                  </Col>
                  {awayTeam.linescores.map((score, index) => (
                    <Col className='col-1' style={{paddingRight: '5px', paddingLeft: '5px', fontFamily: 'Helvetica, Arial, sans-serif', fontSize:'x-small', opacity:'.65'}}>
                      {score.value}
                    </Col>
                   ))}
                   <Col className='col-1' style={{paddingRight: '5px', paddingLeft: '5px', fontFamily: 'Helvetica, Arial, sans-serif', fontSize:'x-small', color: awayTeam.winner ? 'goldenrod' : 'inherit'}}>
                    {awayTeam.score}
                   </Col>
                   {/* <Col className='col-1' style={{paddingRight: '5px', paddingLeft: '5px'}}>
                     {awayTeam.winner ? 'W' : ''}
                    </Col> */}
                </Row>
                <Row>
                  <Col className='col-6' style={{fontFamily: 'Helvetica, Arial, sans-serif',  fontSize:'xx-small', opacity:'.65'}}>
                    {homeTeam.winner === null ? formattedTime : formattedDate}
                  </Col>
                </Row>
              </Container>                
            ) : (
              <p>No scores available</p>
            )}
        </div>
      </div>
  );
}