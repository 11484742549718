import { url } from "./MongoConstants";

const createResults = async (event, betData) => {
    event.preventDefault();

    // Make the POST request to the server
    try {
        const server = url;
        const response = await fetch(server + '/api/create/results', {
            method: 'POST',
            headers: {'Content-Type': 'application/json',},
            body: JSON.stringify(betData),
        });

        if (response.ok) {
            const data = await response.json();
            console.log('Results logged successfully:', data);
            return(data)
        } else {
            console.log('Failed to log result');
            return('Failed to log result')
        }
    } catch (error) {
        console.error('An error occurred:', error);
        return('An error occurred:', error)
    }
};

export default createResults;