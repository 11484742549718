import React, { useEffect, useState } from "react";
import { Table, Form, Col} from "react-bootstrap";
import getBets from "../../MongoDb/GetBets";
import LeaguePicker from "../LeaguePicker";
import KPITiles from "../KPITile";
import { useMobile } from "../MobileContext";
import './BetsHistory.css';

export default function BetsHistory() {
  const [selectedLeagues, setSelectedLeagues] = useState([]);

  const isMobile = useMobile()
  const divClassName = isMobile ? 'd-flex-mobile' : 'd-flex';
  const isMultiSelect = true;
  const [bets, setBets] = useState([]);
  const [filteredBets, setFilteredBets] = useState([]);
  const [filters, setFilters] = useState({
    Bet_Team: "",
    Sport: "",
    Wager_Type: "",
    Bet_Date: ""
  });

  useEffect(() => {
    const fetchBets = async () => {
      try {
        const bets = await getBets();
        setBets(bets);
      } catch (error) {
        console.error('Failed to get bets:', error);
      }
    };

    fetchBets();
  }, []);

  useEffect(() => {
    // Apply filters to bets
    const filtered = bets.filter((bet) => {
      console.log("Bet_Team " +  bet.Bet_Team)
      console.log("Sport " +  bet.Sport)
      console.log("Wager_Type " +  bet.Wager_Type)
      return (
        bet.Bet_Team.toLowerCase().includes(filters.Bet_Team.toLowerCase()) &&
        (selectedLeagues.length === 0 || selectedLeagues.includes(bet.Sport)) &&
        bet.Sport.toLowerCase().includes(filters.Sport.toLowerCase()) &&
        bet.Wager_Type.toLowerCase().includes(filters.Wager_Type.toLowerCase()) &&
        bet.Bet_Date.includes(filters.Bet_Date)
      );
    });

    // Sort bets by Bet_Date in descending order (most recent first)
    const sorted = filtered.sort((a, b) => {
      return new Date(b.Bet_Date) - new Date(a.Bet_Date);
    });

    setFilteredBets(sorted);
  }, [bets, filters, selectedLeagues]);

  let betsHead = null;
  let betsRows = null;
  
  let betWins = 0;
  let betLosses = 0;
  let betPushes = 0;
  let betProfit = 0;
  let betAmount = 0;
  let betUnits = 0;
  let betUnitProfits = 0;

  if (filteredBets.length > 0) {
    const columnOrder = [
      'Bet_Date',
      'Sport',
      'Bet_Team',
      'Wager_Type',
      'Spread',
      'Odds',
      'Units',
      'Risk_Amount',
      'To_Win_Amount',
      'Result',
      'Result Units',
      'Result Profit',
    ];

    betsHead = columnOrder.map((key) => (
      <th key={key}>{key}</th>
    ));

    betsRows = filteredBets.map((item, index) => {
      if (item['Result'] === 'W' || item['Result'] === 'L' || item['Result'] === 'P') {
        betAmount += parseFloat(item['Risk_Amount']) || 0;
        betUnits += parseFloat(item['Units']) || 0;
        betUnitProfits += parseFloat(item['Result Units']) || 0;
        betProfit += parseFloat(item['Result Profit']) || 0;
        if (item['Result'] === 'W') {
          betWins += 1;
        } else if (item['Result'] === 'L') {
          betLosses += 1;
        } else if (item['Result'] === 'P') {
          betPushes += 1;
        }
      }
      const columns = columnOrder.map((key) => {
        let value = item[key];
        if (['Risk_Amount', 'To_Win_Amount', 'Result Profit'].includes(key)) {
          const numberFormat = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
          });
          value = numberFormat.format(value);
        }
        return <td key={key} className="betRow">{value}</td>;
      });
      return <tr key={index}>{columns}</tr>;
    });
  }

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleLeagueSelection = (shortName) => {
    setSelectedLeagues(shortName);
  };

  const uniqueBetTeams = [...new Set(bets.map((bet) => bet.Bet_Team))];
  const uniqueSports = [...new Set(bets.map((bet) => bet.Sport))];
  const uniqueWagerTypes = [...new Set(bets.map((bet) => bet.Wager_Type))];
  
  const kpi_test = [
    {
      'label' : 'Record',
      'value' : betWins + "-" +  betLosses + "-" + betPushes,
      'accent' : betWins / (betWins + betLosses),
      'value_format' : 'Text',
      'accent_format' : 'Percent',
    },
    {
      'label' : 'Wager Amount',
      'value' : betAmount,
      'accent' : betProfit,
      'value_format' : 'Dollar',
      'accent_format' : 'Dollar',
    },
    {
      'label' : 'Wager Units',
      'value' : betUnits.toFixed(2),
      'accent' : betUnitProfits.toFixed(2),
      'value_format' : 'Text',
      'accent_format' : 'Text',
    }
  ]

  return (
    <div className="bets-page" style={{ paddingBottom: '100px' }}>
      {typeof kpi_test === 'undefined' || Object.keys(kpi_test).length === 0 || kpi_test === 'Failed to get bets'? (
        <div>KPIs Loading</div>
      ) : (
        <KPITiles kpis={kpi_test} title={'Record'} />
      )}
      <h3 className="bet-header">Bet History</h3>
      <div className={divClassName}>
        <Form.Group controlId="formSport" style={{'margin-bottom': '.01rem'}}>
          <Form.Row>
            <Col>
            <Form.Label column>Sport</Form.Label>
              <LeaguePicker onSelectLeagues={handleLeagueSelection} width={isMobile? 20 : 40} isMultiSelect={isMultiSelect} />
            </Col>
          </Form.Row>
        </Form.Group>

        <Form.Group controlId="formBetTeam" style={{'margin-bottom': '.01rem'}}>
          <Form.Label column>Bet Team</Form.Label>
          <Form.Control
            as="select"
            name="Bet_Team"
            value={filters.Bet_Team}
            onChange={handleFilterChange}
            style={{'background-color': '#303134', border:'none', color: 'white', opacity: '.65'}}
          >
            <option value="">All</option>
            {uniqueBetTeams.map((team) => (
              <option key={team} value={team}>{team}</option>
            ))}
          </Form.Control>
        </Form.Group>
        
        <Form.Group controlId="formWagerType" style={{'margin-bottom': '.01rem', 'paddingBottom': '.5rem' }}>
          <Form.Label column>Wager Type</Form.Label>
          <Form.Control
            as="select"
            name="Wager_Type"
            value={filters.Wager_Type}
            onChange={handleFilterChange}
            style={{'background-color': '#303134', border:'none', color: 'white', opacity: '.65'}}
          >
            <option value="">All</option>
            {uniqueWagerTypes.map((type) => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>
      <Table responsive striped bordered size="sm" variant="dark" style={{ marginBottom: '0' }}>
        <thead className="betHeader">
          <tr>{betsHead}</tr>
        </thead>
        <tbody>
          {betsRows}
        </tbody>
      </Table>
    </div>
  );
}
