import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import getBets from "../../MongoDb/GetBets";
import getInvestments from '../../MongoDb/GetInvestments';
import { Colors } from 'chart.js';
import { Form, Col } from 'react-bootstrap';
import LeaguePicker from "../LeaguePicker";
import './Graphs.css';
import { useMobile } from '../MobileContext';

Chart.register(Colors);
Chart.defaults.color = '#C5C5C5';
Chart.defaults.borderColor = '#525252';


const AcquisitionsChart = () => {
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const chartRef4 = useRef(null);
  const chartRef5 = useRef(null);
  const chartRef6 = useRef(null);
  const chartRef7 = useRef(null);

  const [bets, setBets] = useState([]);

  const isMultiSelect = true;
  const [selectedLeagues, setSelectedLeagues] = useState([]);

  const isMobile = useMobile()
  const graphContainerClass = isMobile ? 'graph-container-mobile' : 'graph-container'
  const divClassName = isMobile ? 'd-flex-mobile' : 'd-flex';

  const [filteredBets, setFilteredBets] = useState([]);
  const [filters, setFilters] = useState({
    Bet_Team: "",
    Sport: "",
    Wager_Type: "",
    Bet_Date: ""
  });

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleLeagueSelection = (shortName) => {
    setSelectedLeagues(shortName);
  };

  const uniqueBetTeams = [...new Set(filteredBets.map((bet) => bet.Bet_Team))];
  const uniqueWagerTypes = [...new Set(filteredBets.map((bet) => bet.Wager_Type))];

  useEffect(() => {
    // Apply filters to bets
    const filtered = bets.filter((bet) => {
      return (
        bet.Bet_Team.toLowerCase().includes(filters.Bet_Team.toLowerCase()) &&
        (selectedLeagues.length === 0 || selectedLeagues.includes(bet.Sport)) &&
        bet.Sport.toLowerCase().includes(filters.Sport.toLowerCase()) &&
        bet.Wager_Type.toLowerCase().includes(filters.Wager_Type.toLowerCase()) &&
        bet.Bet_Date.includes(filters.Bet_Date)
      );
    });

    // Sort bets by Bet_Date in descending order (most recent first)
    const sorted = filtered.sort((a, b) => {
      return new Date(a.Bet_Date) - new Date(b.Bet_Date);
    });

    setFilteredBets(sorted);
  }, [filters, selectedLeagues]);

  useEffect(() => {
    let chart1;
    let chart2;
    let chart3;
    let chart4;
    let chart5;
    let chart6;
    let chart7;

    const createCharts = () => {      

      const total_profit_by_date = filteredBets.reduce((accumulator, bet) => {
        const { Bet_Date, 'Result Profit': ResultProfit } = bet;
      
        // Check if there is already an entry for this Bet_Date
        const existingEntry = accumulator.find((entry) => entry.Bet_Date === Bet_Date);
      
        if (existingEntry) {
          // If an entry exists, update its Result Profit by adding the current bet's Result Profit
          existingEntry['Total Result Profit'] += parseFloat(ResultProfit);
        } else {
          // If no entry exists, create a new entry for this Bet_Date
          accumulator.push({
            Bet_Date,
            'Total Result Profit': parseFloat(ResultProfit),
          });
        }
        return accumulator;
      }, []);
            
      //Create the team and league summary datasets
      const leagueSummary = {};
      const teamSummary = {};
      const wagerSummary = {};

      filteredBets.forEach((bet) => {
        const { Sport, Bet_Team, Wager_Type, 'Result Profit': ResultProfit } = bet;
       
        // Update leagueSummary
        if (!leagueSummary[Sport]) {
          leagueSummary[Sport] = {
            TotalResultProfit: 0,
            BetCount: 0,
          };
        }
        leagueSummary[Sport].TotalResultProfit += parseFloat(ResultProfit);
        leagueSummary[Sport].BetCount += 1;

        // Update teamSummary
        if (!teamSummary[Bet_Team]) {
          teamSummary[Bet_Team] = {
            TotalResultProfit: 0,
            BetCount: 0,
          };
        }
        teamSummary[Bet_Team].TotalResultProfit += parseFloat(ResultProfit);
        teamSummary[Bet_Team].BetCount += 1;

        // Update wagerSummary
        if (!wagerSummary[Wager_Type]) {
          wagerSummary[Wager_Type] = {
            TotalResultProfit: 0,
            BetCount: 0,
          };
        }
        wagerSummary[Wager_Type].TotalResultProfit += parseFloat(ResultProfit);
        wagerSummary[Wager_Type].BetCount += 1;
      });
      
      console.log(leagueSummary)
      console.log(teamSummary)
      console.log(wagerSummary)

      // Round the TotalResultProfit to 2 decimal places in both summaries
      Object.keys(leagueSummary).forEach((sport) => {
        leagueSummary[sport].TotalResultProfit = parseFloat(leagueSummary[sport].TotalResultProfit.toFixed(2));
      });
      Object.keys(teamSummary).forEach((team) => {
        teamSummary[team].TotalResultProfit = parseFloat(teamSummary[team].TotalResultProfit.toFixed(2));
      });
      Object.keys(wagerSummary).forEach((type) => {
        wagerSummary[type].TotalResultProfit = parseFloat(wagerSummary[type].TotalResultProfit.toFixed(2));
      });

      //Sort into Specific Datasets
      const leagueDataByMostBet = Object.keys(leagueSummary).map((sport) => ({
        label: sport,
        BetCount: leagueSummary[sport].BetCount,
        TotalResultProfit: leagueSummary[sport].TotalResultProfit,
      }));
      leagueDataByMostBet.sort((a, b) => b.BetCount - a.BetCount);

      const leagueDataByMostWon = Object.keys(leagueSummary).map((sport) => ({
        label: sport,
        BetCount: leagueSummary[sport].BetCount,
        TotalResultProfit: leagueSummary[sport].TotalResultProfit,
      }));
      leagueDataByMostWon.sort((a, b) => b.TotalResultProfit - a.TotalResultProfit);

      const teamDataByMostBet = Object.keys(teamSummary)
      .map((Bet_Team) => ({
        label: Bet_Team,
        BetCount: teamSummary[Bet_Team].BetCount,
        TotalResultProfit: teamSummary[Bet_Team].TotalResultProfit,
      }))
      teamDataByMostBet.sort((a, b) => b.BetCount - a.BetCount);
      
      const teamDataByMostWon = Object.keys(teamSummary)
      .map((Bet_Team) => ({
        label: Bet_Team,
        BetCount: teamSummary[Bet_Team].BetCount,
        TotalResultProfit: teamSummary[Bet_Team].TotalResultProfit,
      }))
      teamDataByMostWon.sort((a, b) => b.TotalResultProfit - a.TotalResultProfit);

      const wagerDataByMostBet = Object.keys(wagerSummary).map((type) => ({
        label: type,
        BetCount: wagerSummary[type].BetCount,
        TotalResultProfit: wagerSummary[type].TotalResultProfit,
      }));
      wagerDataByMostBet.sort((a, b) => b.BetCount - a.BetCount); 
      const wagerDataByMostWon = Object.keys(wagerSummary).map((type) => ({
        label: type,
        BetCount: wagerSummary[type].BetCount,
        TotalResultProfit: wagerSummary[type].TotalResultProfit,
      }));
      wagerDataByMostWon.sort((a, b) => b.TotalResultProfit - a.TotalResultProfit); 

      const chartOptions1 = {
        type: 'bar',
        data: {
          labels: total_profit_by_date.map((day) => day.Bet_Date),
          datasets: [
            {
              label: 'Daily Profit',
              data: total_profit_by_date.map((day) => day['Total Result Profit']),
              backgroundColor: total_profit_by_date.map((day) => day['Total Result Profit'] > 0 ? 'rgba(218, 165, 32, .5)' : 'rgba(255, 99, 132, 0.5)'),
            },
            {
              label: 'Total Profit',
              data: total_profit_by_date.map((day, index, arr) =>
                arr.slice(0, index + 1).reduce((sum, item) => sum + item['Total Result Profit'], 0)
              ),
              type: 'line', // Display as a line dataset
              fill: false, // Don't fill under the line
              backgroundColor: '#FFFFFF',
              borderColor: 'white',
              borderWidth: '2',
              pointRadius: 0,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true, // Start the y-axis at zero
            },
          },
        },
      };
      
      //Bet Count by Sport
      const chartOptions2 = {
        type: 'bar',
        data: {
          labels: leagueDataByMostBet.map((item) => item.label),
          datasets: [
            {
              label: 'Count',
              data: leagueDataByMostBet.map((item) => item.BetCount),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scale: {
            ticks: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false, // Hide the legend
            }
          },
        },
      };

      //Profit by Sport
      const chartOptions3 = {
        type: 'bar',
        data: {
          labels: leagueDataByMostWon.map((item) => item.label),
          datasets: [
            {
              label: 'Profit',
              data: leagueDataByMostWon.map((item) => item.TotalResultProfit),
              backgroundColor: leagueDataByMostWon.map((item) => item.TotalResultProfit > 0 ? 'rgba(218, 165, 32, .5)' : 'rgba(255, 99, 132, 0.5)'),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scale: {
            y: {
              beginAtZero: true
            }
          },
          plugins: {
            legend: {
              display: false, // Hide the legend
            },
          },
        },
      };

      //Bet Count by Team
      const chartOptions4 = {
        type: 'bar',
        data: {
          labels: teamDataByMostBet.map((item) => item.label),
          datasets: [
            {
              label: 'Count',
              data: teamDataByMostBet.map((item) => item.BetCount),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scale: {
            ticks: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false, // Hide the legend
            },
          },
        },
      };

      //Profit by Team
      const chartOptions5 = {
        type: 'bar',
        data: {
          labels: teamDataByMostWon.map((item) => item.label),
          datasets: [
            {
              label: 'Profit',
              data: teamDataByMostWon.map((item) => item.TotalResultProfit),
              backgroundColor: teamDataByMostWon.map((item) => item.TotalResultProfit > 0 ? 'rgba(218, 165, 32, .5)' : 'rgba(255, 99, 132, 0.5)'),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scale: {
            ticks: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false, // Hide the legend
            },
          },
        },
      };

      //Bet Count by Wager
      const chartOptions6 = {
        type: 'bar',
        data: {
          labels: wagerDataByMostBet.map((item) => item.label),
          datasets: [
            {
              label: 'Count',
              data: wagerDataByMostBet.map((item) => item.BetCount),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scale: {
            ticks: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false, // Hide the legend
            },
          },
        },
      };

      //Profit by Wager
      const chartOptions7 = {
        type: 'bar',
        data: {
          labels: wagerDataByMostWon.map((item) => item.label),
          datasets: [
            {
              label: 'Profit',
              data: wagerDataByMostWon.map((item) => item.TotalResultProfit),
              backgroundColor: wagerDataByMostWon.map((item) => item.TotalResultProfit > 0 ? 'rgba(218, 165, 32, .5)' : 'rgba(255, 99, 132, 0.5)'),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scale: {
            ticks: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false, // Hide the legend
            },
          },
        },
      };

      chart1 = new Chart(chartRef1.current, chartOptions1);
      chart2 = new Chart(chartRef2.current, chartOptions2);
      chart3 = new Chart(chartRef3.current, chartOptions3);
      chart4 = new Chart(chartRef4.current, chartOptions4);
      chart5 = new Chart(chartRef5.current, chartOptions5);
      chart6 = new Chart(chartRef6.current, chartOptions6);
      chart7 = new Chart(chartRef7.current, chartOptions7);

    };

    createCharts();

    return () => {
      chart1.destroy();
      chart2.destroy();
      chart3.destroy();
      chart4.destroy();
      chart5.destroy();
      chart6.destroy();
      chart7.destroy();
    };
  }, [filteredBets]);

  useEffect(() => {
    const fetchBets = async () => {
      try {
        const bets = await getBets();
        console.log(bets)
        setBets(bets);
        const filteredBets = bets.filter((bet) => {
          // Check if 'Result Profit' is not blank (assuming it's a string)
          return bet.hasOwnProperty('Result Profit');
        });
        setFilteredBets(filteredBets);
      } catch (error) {
        console.error('Failed to get bets:', error);
      }
    };
    fetchBets();
  }, []);

  return (
    <div>
        <h3 className="bet-header">Stats</h3>
        <div className={divClassName} style={{'color': 'white'}}>
          <Form.Group controlId="formSport" style={{'margin-bottom': '.01rem'}}>
            <Form.Row>
              <Col>
              <Form.Label column>Sport</Form.Label>
                <LeaguePicker onSelectLeagues={handleLeagueSelection} width={isMobile ? 20 : 40} isMultiSelect={isMultiSelect} />
              </Col>
            </Form.Row>
          </Form.Group>
          <Form.Group controlId="formBetTeam" style={{'margin-bottom': '.01rem'}}>
            <Form.Label column>Bet Team</Form.Label>
            <Form.Control
              as="select"
              name="Bet_Team"
              value={filters.Bet_Team}
              onChange={handleFilterChange}
              style={{'background-color': '#303134', border:'none', color: 'white', opacity: '.65'}}
            >
              <option value="">All</option>
              {uniqueBetTeams.map((team) => (
                <option key={team} value={team}>{team}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formWagerType" style={{'margin-bottom': '.01rem', 'paddingBottom': '.5rem' }}>
            <Form.Label column>Wager Type</Form.Label>
            <Form.Control
              as="select"
              name="Wager_Type"
              value={filters.Wager_Type}
              onChange={handleFilterChange}
              style={{'background-color': '#303134', border:'none', color: 'white', opacity: '.65'}}
            >
              <option value="">All</option>
              {uniqueWagerTypes.map((type) => (
                <option key={type} value={type}>{type}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formBetDate">
            <Form.Label column>Bet Date</Form.Label>
            <Form.Control
              type="text"
              name="Bet_Date"
              value={filters.Bet_Date}
              onChange={handleFilterChange}
              style={{'background-color': '#303134', border:'none', color: 'white', opacity: '.65'}}
            />
          </Form.Group>
        </div>
        <div className='graph-tile' style={{height: '400px'}}>
            <h4 style={{color: 'white'}}>Profits</h4>
            <canvas ref={chartRef1} id="daily_profit_1" style={{paddingBottom: '1.5rem'}}/>
        </div>
        <div className={graphContainerClass}>
          <div className='graph-tile'>
            <h4 style={{ color: 'white' }}># Bets by Sport</h4>
            <canvas ref={chartRef2} id="daily_profit_2" style={{paddingBottom: '1.5rem' }}/>
          </div>
          <div className='graph-tile'>
            <h4 style={{ color: 'white' }}>Profit by Sport</h4>
            <canvas ref={chartRef3} id="daily_profit_3" style={{paddingBottom: '1.5rem' }}/>
          </div>
          <div className='graph-tile'>
            <h4 style={{ color: 'white' }}># Bets by Team</h4>
            <canvas ref={chartRef4} id="daily_profit_4" style={{paddingBottom: '1.5rem' }}/>
          </div>
          <div className='graph-tile'>
            <h4 style={{ color: 'white' }}>Profit by Team</h4>
            <canvas ref={chartRef5} id="daily_profit_5" style={{paddingBottom: '1.5rem' }}/>
          </div>
          <div className='graph-tile'>
            <h4 style={{ color: 'white' }}># Bets by Wager</h4>
            <canvas ref={chartRef6} id="daily_profit_6" style={{paddingBottom: '1.5rem' }}/>
          </div>
          <div className='graph-tile'>
            <h4 style={{ color: 'white' }}>Profit by Wager</h4>
            <canvas ref={chartRef7} id="daily_profit_7" style={{paddingBottom: '1.5rem' }}/>
          </div>
        </div>
    </div>
  );
};

export default AcquisitionsChart;
