import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import SportsFootballIcon from '@material-ui/icons/SportsFootball';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import SportsHockeyIcon from '@material-ui/icons/SportsHockey';
import { useMobile } from './MobileContext';
import './LeaguePicker.css';

const LeaguePicker = ({ onSelectLeagues, width, isMultiSelect }) => {
    const isMobile = useMobile();
    const leagueButtonClass = isMobile ? 'league-button-mobile' : '';
    const leagueTextClass = isMobile ? 'd-flex-mobile' : 'd-flex';
    const leagueGroupClass = isMobile ? 'league-group-mobile' : 'league-group';

    const [selectedLeagues, setSelectedLeagues] = useState([]);

    const soccerLeagues = ['BUND', 'EPL', 'LIGUE', 'SERA', 'LALIGA', 'FIFA'];

    const handleLeagueSelection = (shortName) => {
        let updatedLeagues = [];

        if (isMultiSelect) {
            if (shortName === 'FIFA') {
                updatedLeagues = selectedLeagues.includes('FIFA')
                    ? selectedLeagues.filter((league) => !soccerLeagues.includes(league))
                    : [...selectedLeagues, ...soccerLeagues.filter((league) => !selectedLeagues.includes(league))];
            } else if (shortName.includes('NCAA')) {
                updatedLeagues = selectedLeagues.includes(shortName)
                    ? selectedLeagues.filter((league) => league !== shortName)
                    : [...selectedLeagues, shortName];
            } else {
                updatedLeagues = selectedLeagues.includes(shortName)
                    ? selectedLeagues.filter((league) => league !== shortName)
                    : [...selectedLeagues, shortName];
            }
        } else {
            updatedLeagues = shortName === 'FIFA' ? soccerLeagues : [shortName];
        }

        setSelectedLeagues(updatedLeagues);
        onSelectLeagues(updatedLeagues);
    };

    const isLeagueSelected = (shortName) => selectedLeagues?.includes(shortName);

    const background_color = '#303134';
    const selected_color = 'gray';

    return (
        <ButtonGroup className={leagueGroupClass}>
            {[
                { name: 'NFL', icon: <SportsFootballIcon /> },
                { name: 'NBA', icon: <SportsBasketballIcon /> },
                { name: 'NHL', icon: <SportsHockeyIcon /> },
                { name: 'MLB', icon: <SportsBaseballIcon /> },
                { name: 'NCAAF', icon: <SportsFootballIcon /> },
                { name: 'NCAAB', icon: <SportsBasketballIcon /> },
                { name: 'FIFA', icon: <SportsSoccerIcon /> },
            ].map(({ name, icon }) => (
                <Button
                    key={name}
                    variant={isLeagueSelected(name) ? 'primary' : 'secondary'}
                    onClick={() => handleLeagueSelection(name)}
                    className={leagueButtonClass}
                    style={{
                        background: isLeagueSelected(name) ? selected_color : background_color,
                        color: isLeagueSelected(name) ? background_color : selected_color,
                    }}
                >
                    {React.cloneElement(icon, { style: { fontSize: width } })}
                    <div className={leagueTextClass} style={{ color: isLeagueSelected(name) ? background_color : selected_color }}>
                        {name}
                    </div>
                </Button>
            ))}
        </ButtonGroup>
    );
};

export default LeaguePicker;
