import React from 'react';
import './App.css';
import Navbar from './components/navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/pages/Home'
import Footer from './components/Footer';
import BetsHome from './components/pages/BetsHome';
import BetsHistory from './components/pages/BetsHistory';
import InvestmentsNew from './components/pages/InvestmentsNew';
import 'bootstrap/dist/css/bootstrap.min.css'
import AcquisitionsChart from './components/pages/Graphs';
import CreateBetForm from './components/pages/CreateBet';
import OpenBets from './components/pages/BetResults';
import Scores from './components/pages/Scores';
import Model from './components/pages/Model';
import Schedule from './components/pages/Schedule';
import { MobileProvider } from './components/MobileContext';

function App() {
  return (
    <>
    <MobileProvider>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/bets_home' exact component={BetsHome} />
          <Route path='/investments' exact component={InvestmentsNew} />
          <Route path='/stats' exact component={AcquisitionsChart} />
          <Route path='/bets_history' exact component={BetsHistory} />
          <Route path='/create_bet' exact component={CreateBetForm} />
          <Route path='/open_bets' exact component={OpenBets} />
          <Route path='/scores' exact component={Scores} />
          <Route path='/schedule' exact component={Schedule} />          
        </Switch>          
        <Footer/>
      </Router>
      </MobileProvider>
    </>
  );
}


export default App;
