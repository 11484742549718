import React from 'react';
import './KPITile.css';
import { useMobile } from './MobileContext'; // Import the useMobile hook

const KPITiles = ({kpis, title}) => {
    const isMobile = useMobile()
    const numKPIs = isMobile ? 4 : kpis.length;

    return (
        <>
        {isMobile ? (
            <div>
                <h3 className='col-header-mobile'>{title}</h3>
                <div className='kpi-tiles-mobile'>
                    {kpis.slice(0, numKPIs).map((kpi) => <KPITile kpi={kpi} />)}
                </div>
            </div> 
        ) : (
            <div>
                <h3 className='col-header'>{title}</h3>
                <div className='kpi-tiles'>
                    {kpis.slice(0, numKPIs).map((kpi) => <KPITile kpi={kpi} />)}
                </div>
            </div> 
        )}
        </>
        
        
    )
}

const KPITile = ({ kpi }) => {
    const {
        label,
        value,
        accent,
        value_format,
        accent_format
    } = kpi;
    
    let accent_color_kpi = ''
    let accent_formatted = ''
    let value_formatted = ''

    switch (value_format) {
        case "Dollar":
            value_formatted = Intl.NumberFormat('en-US', {
                style: 'currency', 
                currency: 'USD'
              }).format(Number(value));
            break;
        default:
            value_formatted = value
            break;
    }
    switch (accent_format) {
        case "Dollar":
            accent_formatted = Intl.NumberFormat('en-US', {
                style: 'currency', 
                currency: 'USD'
              }).format(Number(accent));
            break;
        case "Percent":
            accent_formatted = (accent*100).toFixed(2) + "%"
            break;
        default:
            accent_formatted = accent
            break;
    }

    if (accent > 0) {
        accent_color_kpi = "#00C358"
    }else {
        accent_color_kpi = "#F32535"
    }

    return (
        <div className="kpi-tile">
            <div className="kpi-label">
                {label} 
            </div>
            <div className="kpi-value">
                {value_formatted} 
            </div>  
            <div className="kpi-accent" style={{color:accent_color_kpi}}>
                {accent_formatted} 
            </div>  
        </div>

    );
};

export default KPITiles;