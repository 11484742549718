import React, { useState, useEffect } from 'react';
import {Users, DollarSign, Dribbble, BarChart, Cpu} from 'react-feather';
import { Link } from 'react-router-dom';
import './navbar.css'
function Navbar() {
    const [windowDimension, setWindowDimension] = useState(null);

    useEffect(() => {
        setWindowDimension(window.innerWidth);
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowDimension <= 640;

    return (
        <>
            {isMobile ? (
                <nav className='nav-mobile-wrapper'>
                    <ul className='nav-mobile-menu'>
                        <li className='nav-mobile-item'>
                            <Dribbble size={24}/>
                            <Link to='/bets_home' className='nav-links'>
                                Bets
                            </Link>
                        </li>
                        <li className='nav-mobile-item'>
                            <Users size={24}/>
                            <Link to='/investments' className='nav-links'>
                                Investors
                            </Link>
                        </li>
                        <li className='nav-mobile-item'>
                            <BarChart size={24}/>
                            <Link to='/stats' className='nav-links'> 
                                Stats
                            </Link>
                        </li>
                        <li className='nav-mobile-item'>
                            <Cpu size={24}/>
                            <Link to='/Schedule' className='nav-links'>
                                Schedule
                            </Link>
                        </li>
                    </ul>
                </nav>
            ) : (
                <nav className='nav-wrapper'>
                    <Link to='/' className='nav-logo'>
                        <h1>The Brain</h1>
                    </Link>
                    <ul className='nav-menu'>
                        <li className='nav-item'>
                            <Link to='/bets_home' className='nav-links'>
                                Bets
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/investments' className='nav-links'>
                                Investors
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/stats' className='nav-links'>
                                Stats
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/Schedule' className='nav-links'>
                                Model
                            </Link>
                        </li>
                    </ul>
                </nav>
            )}
        </>
    )
}

export default Navbar
