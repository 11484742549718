import React, { useEffect, useState } from "react";
import { Table, Form } from "react-bootstrap";
import getInvestments from "../../MongoDb/GetInvestments";
import './InvestmentsNew.css';
import KPITiles from "../KPITile";

export default function InvestmentsNew() {
  const [investments, setInvestments] = useState();
  const [sortColumn, setSortColumn] = useState('Portfolio Value');
  const [sortDirection, setSortDirection] = useState("desc");

  let numInvestor = 0;
  let totalInvestment = 0;
  let totalProfit = 0;

  useEffect(() => {
    const fetchInvestments = async () => {
      try {
        const investments = await getInvestments();
        setInvestments(JSON.parse(investments))
        
      } catch (error) {
        console.error('Failed to get investments:', error);
      }
    };
    fetchInvestments();
  }, []);

  

  let investmentsHead = null;
  let investmentsRows = null;

  const handleHeaderClick = (columnName) => {
    if (sortColumn === columnName) {
      // If the same column is clicked again, toggle the sort direction.
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // If a different column is clicked, set the new column and direction.
      setSortColumn(columnName);
      setSortDirection("desc");
    }
  };

  if (investments != undefined) {
    const keys = Object.keys(investments[0]);
    
    const cleanUpAndSortInvestments = (investments) => {
      return investments.sort((a, b) => {
        if (sortColumn) {
          const aValue = a[sortColumn];
          const bValue = b[sortColumn];
    
          // Determine the data type of the column
          const isNumeric = (value) => /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/.test(value);
          const isPercentage = (value) => /%$/.test(value);
    
          if (isNumeric(aValue) && isNumeric(bValue)) {
            const aNumber = parseFloat(aValue.replace(/[$,%]/g, ""));
            const bNumber = parseFloat(bValue.replace(/[$,%]/g, ""));
    
            if (sortDirection === "asc") {
              return aNumber - bNumber;
            } else {
              return bNumber - aNumber;
            }
          } else if (isPercentage(aValue) && isPercentage(bValue)) {
            const aNumber = parseFloat(aValue.replace(/[$,%]/g, "").replace(",", ""));
            const bNumber = parseFloat(bValue.replace(/[$,%]/g, "").replace(",", ""));
    
            if (sortDirection === "asc") {
              return aNumber - bNumber;
            } else {
              return bNumber - aNumber;
            }
          } else {
            // For non-numeric columns, perform a case-insensitive comparison
            if (sortDirection === "asc") {
              return aValue.localeCompare(bValue, undefined, { sensitivity: 'base' });
            } else {
              return bValue.localeCompare(aValue, undefined, { sensitivity: 'base' });
            }
          }
        }
        return 0; // No sorting column specified or invalid data, keep the order as is.
      });
    };
    
    const sortedInvestments = cleanUpAndSortInvestments(investments);

    const renderSortingIndicator = (columnName) => {
      if (columnName === sortColumn) {
        if (sortDirection === "asc") {
          return <span className="sort-indicator">&#9650;</span>; // Up arrow
        } else {
          return <span className="sort-indicator">&#9660;</span>; // Down arrow
        }
      }
      return null;
    };

    // const sortedInvestments = investments.sort((a, b) => b['Portfolio Value'] - a['Total Investment']);
    investmentsHead = keys.map(key => (
      <th
        key={key}
        onClick={() => handleHeaderClick(key)}
        className={key === sortColumn ? "sortable active" : "sortable"}
      >
        {key} {renderSortingIndicator(key)}
      </th>
    ));

    investments.forEach((item) => {
      numInvestor += 1
      totalInvestment += parseFloat(item['Total Investment']);
      totalProfit += parseFloat(item['Total Profit']);
    });

    investmentsRows = sortedInvestments.map((item, index) => {
      const columns = keys.map(key => {
        let value = item[key];
        if (key === "Fund % Start of Day" || key === "% Return") {
          value = Number(value).toFixed(2);
          value = `${value}%`;
        }
        if (key === "Total Investment" || key === "Total Profit" || key === "Portfolio Value") {
          const numberFormat = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
          });
          value = numberFormat.format(value);
        }
        return <td key={key} className="investorRow">{value}</td>;
      });
      return <tr key={index}>{columns}</tr>;
    });
  }

  const investorKPIs = [
    {
      'label' : 'Investment',
      'value' : totalInvestment,
      'accent' : ((totalProfit + totalInvestment) - totalInvestment) / totalInvestment,
      'value_format' : 'Dollar',
      'accent_format' : 'Percent',
    },
    {
      'label' : 'Investors',
      'value' : numInvestor,
      'accent' : '',
      'value_format' : 'Text',
      'accent_format' : 'Text',
    }
  ]

  return (
    <div style={{ paddingBottom: '100px' }}>
      <div className="bets-page">
        {typeof investorKPIs === 'undefined' || Object.keys(investorKPIs).length === 0 || investorKPIs === 'Failed to get bets'? (
          <div>KPIs Loading</div>
        ) : (
          <KPITiles kpis={investorKPIs} title={'Fund'} />
        )}
      </div>
      <h3 className="col-header">All Investors</h3>
      <Table responsive striped bordered size="sm" variant="dark" style={{ marginBottom: '0' }}>
        <thead className="investorHeader">
          {investmentsRows ? <tr>{investmentsHead}</tr> : null}
        </thead>
        <tbody>
          {investmentsRows == undefined ? <div> Investments Loading </div> : investmentsRows}
        </tbody>
      </Table>
    </div >
  );
}
