import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import TodaysBets from "../TodaysBets";
import KPITiles from "../KPITile";
import getBetKPIs from "../../MongoDb/GetBetKPIs";
import getTodaysBets from "../../MongoDb/GetTodaysBets";
import getBets from "../../MongoDb/GetBets";
import './BetsHome.css';

export default function BetsHome() {
  const [bets, setBets] = useState([]);
  const [betKPIs, setBetKPIs] = useState();
  const [todaysBets, setTodaysBets] = useState();

  useEffect(() => {
    const fetchBets = async () => {
      try {
        const bets = await getBets();
        setBets(bets);
      } catch (error) {
        console.error('Failed to get bets:', error);
      }
    };
    const fetchBetSummary = async () => {
      try {
        const betKPIs = await getBetKPIs();
        setBetKPIs(betKPIs);
      } catch (error) {
        console.error('Failed to get bet kpis:', error);
      }
    };
    const fetchTodasyBets = async () => {
      try {
        const todaysBets = await getTodaysBets();
        console.log(todaysBets)
        setTodaysBets(todaysBets);
      } catch (error) {
        console.error('Failed to get todasy bets:', error);
      }
    };
    fetchBetSummary();
    fetchBets();
    fetchTodasyBets();
  }, []);

  return (
    <div className="bets-page">
      {typeof betKPIs === 'undefined' || Object.keys(betKPIs).length === 0 || betKPIs === 'Failed to get bets'? (
        <div>KPIs Loading</div>
      ) : (
        <KPITiles kpis={betKPIs} title={'Record'} />
      )}
      <div className="bet-home">
          <h3 className='col-header'>Recent Bets</h3>
          <Link to='/bets_history' className='col-header-seconday'>
              <p>Full History</p>
          </Link>
      </div>
      {todaysBets == undefined ? <div>Bets Loading</div> : <TodaysBets bets={todaysBets}/>}
    </div>
  );
};
