import React from "react";
import "./stats.css";

const StatsMatch = ({ statistics, isDarkMode }) => {
  return (
    <div className="stats-wrapper">
      {statistics.map((statistic, index) => {
        if (+statistic.home === 0 && +statistic.away === 0) return null;
        
        const homeValue = parseFloat(statistic.home)|| 0;
        const awayValue = parseFloat(statistic.away)|| 0;

        // Adjust the values to penalize negative numbers
        let adjustedHomeValue = homeValue > 0 ? homeValue : 0; // Negative values become 0
        let adjustedAwayValue = awayValue > 0 ? awayValue : 0; // Negative values become 0

        let total = adjustedHomeValue + adjustedAwayValue;

        if (total <= 3 && total >= 1 && adjustedHomeValue > 0 && adjustedAwayValue > 0) {
            adjustedHomeValue = adjustedHomeValue - 0.5 || 0;
            adjustedAwayValue = adjustedAwayValue - 0.5 || 0;
            total = adjustedHomeValue + adjustedAwayValue;
        }else{
            adjustedHomeValue = adjustedHomeValue + 15 || 0;
            adjustedAwayValue = adjustedAwayValue + 15 || 0;
            total = adjustedHomeValue + adjustedAwayValue;
        };

        // Amplify the greater percentage
        if (adjustedHomeValue > adjustedAwayValue) {
            adjustedHomeValue *= 2; // Amplify home value by 20%
        } else if (adjustedAwayValue > adjustedHomeValue) {
            adjustedAwayValue *= 2; // Amplify away value by 20%
        }

        // Recalculate total after amplification
        total = adjustedHomeValue + adjustedAwayValue;

        let homePercentage = total > 0 ? Math.round((adjustedHomeValue / total) * 100) : 0;
        let awayPercentage = total > 0 ? Math.round((adjustedAwayValue / total) * 100) : 0;

        if (statistic.type === "Pts Against") {
            [homePercentage, awayPercentage] = [awayPercentage, homePercentage];
          }

        return (
          <div key={index} className="stats-container">
            <div className="stats-main">
              <div className="stats-value">{statistic.away}</div>
              <div className="stats-type">{statistic.type}</div>
              <div className="stats-value">{statistic.home}</div>
            </div>
            <div className="stats-chart single-bar">
              <div
                className="chart-fill away-fill"
                style={{
                  width: `${awayPercentage}%`,
                  // backgroundColor: isDarkMode ? "#fff" : "#001e28",
                }}
              ></div>
              <div
                className="chart-fill home-fill"
                style={{
                  width: `${homePercentage}%`,
                  // backgroundColor: isDarkMode ? "#ff0046" : "#ff0046",
                }}
              ></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StatsMatch;