import { url } from "./MongoConstants";

const getModelDate = async (date_str) => {
    try {
        const server = url;
        const response = await fetch(server + '/api/data/model/date/' + date_str, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const data = await response.json();
            return(data)
        } else {
            console.log('Failed to get model');
            return('Failed to get model');
        }
    } catch (error) {
        console.error('An error occurred:', error);
        return('An error occurred:', error);
    }
};

export default getModelDate;