import BetTile from './BetTile';
import './TodaysBets.css';
import { useMobile } from './MobileContext'; // Import the useMobile hook

const TodaysBets = ({ bets, showresult}) => {
  const isMobile = useMobile()

  return (
    <>
      {isMobile ? (
         <div className="bet-container-mobile">
          {bets.map((bet) => <BetTile key={bet._id} bet={bet} showresult={showresult}/>)}
        </div>
      ) : (
        <div className="bet-container">
          {bets.map((bet) => <BetTile key={bet._id} bet={bet} showresult={showresult}/>)}
        </div>
      )}
     
    </>
     
  );
};

export default TodaysBets;
