import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { Button } from '../button'
import './Home.css'
import brain from '../../images/Brain.jpg'
import newbrain from '../../images/image.png'

function Home() {
    return (
        <>
            {/* <TodaysBets></TodaysBets> */}
            <Container className='containerHome' fluid={true}>
                <Row className='headerRow' noGutters={false}>
                    <Col className='headerCol'>
                        <img src={newbrain} className="headerImage" />
                        <p className='headerTitle'>The Brain Fund</p>
                        <p className='headerBody'>Don't Gamble. Invest.</p>
                        <div className='headerBtns'>
                            <Button className='btns' buttonStyle='btn-outline' buttonSize='btn-small' link='bets_home'>
                                View Results
                            </Button>
                            <Button className='btns' buttonStyle='btn-outline' buttonSize='btn-small' link='invest'>
                                Invest Now
                            </Button>
                        </div>
                    </Col>
                </Row>
                {/*<Row className='secondRow' xl={2} lg={2} md={2} sm={1} xs={1} noGutters={false}>
                    <Col className='appCol'>
                        <Image src={brain} className='phoneDemo' />
                    </Col>
                    <HeaderPanel
                        color='rust'
                        title='Smart Sports Betting'
                        body='We are here to manage the model and the bankroll while you get to be the sharp. Transparent reporting on profits and investors.'
                        button='Learn More'
                        link='invest'
                    />
                </Row>*/}
            </Container>
        </>
    )
}

function HeaderPanel(props) {
    const columnClass = 'panel ' + props.color
    return (
        <Col className={columnClass}>
            <div className='panelContainer'>
                <p className='panelTitle'>{props.title}</p>
                <p className='panelBody'>{props.body}</p>
                <div className='panelBtn'>
                    <Button className='btns' buttonStyle='btn-outline' buttonSize='btn-small' link={props.link}>
                        {props.button}
                    </Button>
                </div>
            </div>
        </Col>
    );
}
export default Home
