import { url } from "./MongoConstants";

const getInvestments = async () => {
    try {
        const server = url;
        const response = await fetch(server + '/api/data/investment_table', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const data = await response.json();
            return(data)
        } else {
            console.log('Failed to get investments');
            return('Failed to get investments');
        }
    } catch (error) {
        console.error('An error occurred:', error);
        return('An error occurred:', error);
    }
};

export default getInvestments;