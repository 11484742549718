import React from "react";
import {Button, Modal } from 'react-bootstrap';

function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{props.header}</h4>
          <p>
            {props.body}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>{props.close}</Button>
        </Modal.Footer>
      </Modal>
    );
}

export default MyVerticallyCenteredModal;
