import React, { useEffect, useState } from "react";
import getOpenBets from "../../MongoDb/GetOpenBets";
import TodaysBets from "../TodaysBets";
import createResults from "../../MongoDb/SubmitResults";
import './BetsHistory.css';
import { Button } from "react-bootstrap";
import MyVerticallyCenteredModal from '../BetConfirmModal'
import { Redirect } from 'react-router-dom';



export default function OpenBets() {
  const [bets, setBets] = useState([]);
  //depends on response from server
  const [resultSent, setResultSent] = React.useState(false);

  const [resultSuccess, setResultSuccess] = React.useState(false);
  //modal that confirms bet success or fail toggle
  const [modalShow, setModalShow] = React.useState(false);
  //toggle that navigates back to the bets page to confirm new bet is created
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
    const fetchBets = async () => {
      try {
        const bets = await getOpenBets();
        setBets(bets);
      } catch (error) {
        console.error('Failed to get bets:', error);
      }
    };
    fetchBets();
  }, []);

  const handleResultSubmit = (event) => {
    let betsToSubmit = [];
    bets.forEach(bet => {
      if (typeof bet['Result'] !== 'undefined' && bet['Result'] !== "") {
        betsToSubmit.push(bet)
      }
    });
    if (resultSent !== true){
      setResultSent(true)
      createResults(event, betsToSubmit)
      .then((response) => {
        setResultSent(false)
        setResultSuccess(true)
        setModalShow(true)
      })
      .catch((error) => {
        setResultSent(false)
        setResultSuccess(false)
        setModalShow(true)
      });
    }
  };

  return (
    <div className="bets-page">
      <div className="bet-header">
        <h3>Open Bets</h3>
        <Button variant="outline-warning" onClick={handleResultSubmit}>Submit Results</Button>
      </div>  
      {bets == [] ? <div>Bets Loading</div> : <TodaysBets bets={bets} showresult={true} windowDimension={windowDimension}/>}
      {resultSuccess
        ? 
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            setShouldNavigate(true);
          }}
          title='Results Logged'
          header='Results Successfully Created'
          body='If you made a mistake please contact your admin for assistance. Otherwise verify on the bets page for today
          that your bet has been correctly created'
          close='Close'
        />
        : 
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          title='Results not Logged'
          header='Please try again'
          body='The password or some details of the bet were incorrectly logged please try again'
          close='Close'
        />}
        {shouldNavigate && <Redirect to="/bets_home" />}
    </div>
  );
}
