import React, {useState} from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import './BetTile.css';

const BetTile = ({ bet, showresult, windowDimension }) => {
    const [selectedResult, setResult] = useState('');
    
    const {
        Bet_Date,
        Sport,
        Bet_Team,
        Wager_Type,
        Spread,
        Odds,
        Units,
        Risk_Amount,
        To_Win_Amount,
        Result,
        "Result Units": Result_Units,
        "Result Profit": Result_Profit
    } = bet;

    let spread_prefix = ""
    
    if (Wager_Type === "Spread" ||  Wager_Type === "1H Spread" || Wager_Type === "2H Spread") {
        if (Spread > 0) {
            spread_prefix = "+"
        }
    } else{
        if (Wager_Type === "Over") {
            spread_prefix = "o"
        }
        if (Wager_Type === "Under") {
            spread_prefix = "u"
        }
        if (Wager_Type === "Moneyline") {
            spread_prefix = "ML"
        }
    }

    const result_color = Result === undefined || Result === null || Result === 'P' || Result === '' ? '#A4A4AA' : Result === 'W' ? '#00C358' : '#F32535';
    const handleResultSelection = (result) => {
        if (result === selectedResult){
            setResult(""); 
            bet['Result'] = "";
            bet['Result Units'] = 0;
            bet['Result Profit'] = 0;
        }
        else {
            setResult(result);
            bet['Result'] = result;
            if (result === "W") {
                bet['Result Units'] = bet['Units']
                bet['Result Profit'] = bet['To_Win_Amount']
            }else if (result === "L") {
                bet['Result Profit'] = -bet['Risk_Amount']
                if (bet['Odds'] >= 0) {
                    bet['Result Units'] = -1
                } else {
                    bet['Result Units'] = (bet['Units'] * bet['Odds'])/100
                }
            } else {
                bet['Result Units'] = 0
                bet['Result Profit'] = 0
            }
        }
    };

    const isResultSelected = (result) => {
        return selectedResult.includes(result);
    };

    const background_color = '#303134'
    const selected_color = 'gray'
  
    return (
    <div className="bet-tile">
        <div className="bet-info">
            <div className="bet-status">
                    <svg role="presentation" focusable="false" width="36" height="36" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <g fill={Result === '' | Result === undefined || Result === null || Result === 'P' ? '#A4A4AA': Result === 'W'? "#00C358" : "#F32535"}>
                        <g>
                        <g transform="translate(4 4)">
                            <rect opacity="0.3" width="16" height="16" rx="8"></rect>
                            <rect x="4" y="4" width="8" height="8" rx="4"></rect>
                        </g>
                        </g>
                    </g>
                    </svg>
            </div>
            <div>
                <div className="bet-team">{Bet_Team}</div>
                <div className="bet-spread">
                    {spread_prefix}
                    {Spread} ({Odds})
                </div>
                <div className="bet-date">{Bet_Date}</div>
            </div>
        </div>
        {showresult === true ? (
            <div>
                <ButtonGroup vertical className='result-group'>
                <Button
                    variant={isResultSelected('W') ? 'primary' : 'secondary'}
                    onClick={() => handleResultSelection('W')}
                    className='league-button'
                    style={{
                        background: isResultSelected('W') ? result_color : background_color,
                        color: isResultSelected('W') ? background_color : selected_color
                    }}
                >
                    <div className='leaguetext' style={{ color: isResultSelected('W') ? background_color : selected_color }}>W</div>
                </Button>
                <Button
                    variant={isResultSelected('L') ? 'primary' : 'secondary'}
                    onClick={() => handleResultSelection('L')}
                    className='league-button'
                    style={{
                        background: isResultSelected('L') ? result_color : background_color,
                        color: isResultSelected('L') ? background_color : selected_color
                    }}
                >
                    <div className='leaguetext' style={{ color: isResultSelected('L') ? background_color : selected_color }}>L</div>
                </Button>
                <Button
                    variant={isResultSelected('P') ? 'primary' : 'secondary'}
                    onClick={() => handleResultSelection('P')}
                    className='league-button'
                    style={{
                        background: isResultSelected('P') ? result_color : background_color,
                        color: isResultSelected('P') ? background_color : selected_color
                    }}
                >
                    <div className='leaguetext' style={{ color: isResultSelected('P') ? background_color : selected_color }}>P</div>
                </Button>
            </ButtonGroup>
            </div>
        ) : (
            <div>
            </div>
        )}
        {Result === '' || Result === undefined || Result === null ? (
            <div>
                <div className="bet-result">
                    Risk: ${Risk_Amount}
                </div>
                <div className="bet-result">
                    To Win: ${To_Win_Amount}
                </div>
            </div>
        ) : (
            <div>
                <div className="bet-result" style={{color:result_color}}>
                    {Result === "W" ? "WIN" : Result === "L" ? "LOSS" : "PUSH"}
                </div>
                <div className="bet-result">
                    ${Result_Profit}
                </div>
            </div>
        )}
    </div>

  );
};

export default BetTile;
